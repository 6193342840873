@import url('https://fonts.googleapis.com/css2?family=Urbanist&display=swap');

.StripeElement {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    box-sizing: border-box;
    border-radius: 10px;
    /*padding: 25px 14px 9px;*/
    padding: 14.605px 9px;
    font-family: 'Urbanist', sans-serif !important;
    font-weight: 400 !important;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: none;
    color: rgba(255, 255, 255, 0.5) !important;
}

.StripeElement+label {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    padding: 9px 14px 9px;
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    color: rgba(255, 255, 255, 0.44);
}

.form-floating-custom input+label {
    padding: 9px 20px 9px;
}

.cardNumber .StripeElement+label {
    padding: 9px 20px 9px;
}

.StripeElement.StripeElement--focus.StripeElement--invalid,
.StripeElement.StripeElement--invalid {
    border: 1px solid #EB5757;
    outline: none;
}

.StripeElement.StripeElement--focus {
    background: rgba(114, 202, 255, 0.1) !important;
    border: 1px solid #38BBF4;
    outline: rgba(112, 200, 255, 0.416) solid 2px;
    color: #fff !important;
}
.card-field{
    padding:16px 12px 16px 58px !important;
}
