.blogs-wrap {
    padding: 91px 100px;
    background: #150924;
    color: white;
}

.blogs-wrap .heading {
    font-size: 56px;
    font-weight: 700;
    line-height: 68px;
    text-align: left;
    color: white;
    padding: 0;
}

.blogs-wrap .description {
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    text-align: left;
    margin: 0;
}

.blogs-wrap .subheading {
    font-size: 28px;
    font-weight: 700;
    line-height: 68px;
    text-align: left;
}

.blogs-wrap .blogs-card-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 52px;
    margin-top: 30px;
    margin-bottom: 60px;
}

.blogs-wrap .card-wrap {
    /* max-width: 594px; */
    /* width: 48%; */
    background: #271c35;
}

.blogs-wrap .card-wrap .title {
    font-size: 20px;
    font-weight: 800;
    line-height: 28px;
    text-align: left;
    margin: unset;
    font-family: "Inter", sans-serif;
}

.blogs-wrap .card-wrap .description {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    margin-top: 2px;
    margin-bottom: 20px;
}

.blogs-wrap .card-wrap .card-content-wrap {
    padding: 22px;
}

.blogs-wrap .card-wrap .blog-date {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    opacity: 40%;
}

.blogs-wrap .card-wrap .read-btn a {
    font-size: 18.33px;
    font-weight: 800;
    line-height: 13.33px;
    text-align: left;
    color: #06efc5 !important;
    display: flex;
    gap: 15px;
    align-items: center;
    text-decoration: none !important;
}

.blogs-wrap .card-wrap .read-btn a:hover {
    text-decoration: none !important;
}

.blogs-wrap .card-wrap .read-btn .arrow {
    width: 24px;
}

.blogs-wrap .primaryButton {
    background: #06efc5;
    font-size: 18px;
    font-weight: 800;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    color: black;
}

.blogs-wrap .email-wrap {
    margin-bottom: 34px;
    background-color: #231b2e;
    padding: 10px;
    border: 0;
    margin-top: 30px;
    width: 545px;
}

.blogs-wrap .email-input {
    background: transparent;
    border: 0;
    color: white;
}

@media screen and (max-width: 768px) {
    .blogs-wrap {
        padding: 70px 25px;
        text-align: center;
    }

    .blogs-wrap .email-wrap {
        width: 100%;
        flex-direction: column;
        background-color: unset;
        gap: 12px;
    }

    .blogs-wrap .email-input {
        width: 100%;
        background-color: #231b2e;
    }

    .blogs-wrap .email-wrap .primaryButton {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .blogs-wrap .heading {
        font-size: 25px;
        line-height: 68px;
        margin: auto;
    }

    .blogs-wrap .description {
        font-size: 15px;
        font-weight: 400;
        line-height: 34px;
        text-align: center;
    }

    .blogs-wrap .email-wrap {
        margin-top: 0;
    }

    .blogs-wrap .blogs-card-wrapper {
        grid-template-columns: 1fr;
    }

    .blogs-wrap .pagination-wrap .btn-text {
        display: none;
    }

    .blogs-wrap .card-wrap .card-content-wrap {
        padding: 25px 14px;
    }

    .blogs-wrap .subheading {
        font-size: 25px;
        text-align: center;
    }

    .blogs-wrap .card-wrap .title {
        font-size: 15px;
        line-height: 20px;
    }

    .blogs-wrap .card-wrap .blog-date {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .blogs-wrap .card-wrap .description {
        font-size: 15px;
        margin-bottom: 0;
    }

    .blogs-wrap .card-wrap .read-btn {
        font-size: 15px;
    }
}