.banner-wrap-competition {
    background-color: #150924;
    color: white;
    padding: 91px 100px 49px;
}

.banner-buttons {
    align-items: center;
}

.banner-wrap-competition .banner-content {
    text-align: left;
    padding-left: 50px;
    /* max-width: 690px; */
}

.banner-wrap-competition .banner-content .title {
    color: #FFF;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 68px;
    text-align: left;
    font-family: "Inter", sans-serif;
}

.banner-wrap-competition .banner-content .description {
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;

}

.banner-wrap-competition .banner-content .sub-description {
    color: #FFF;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    padding: 16px 0px;
}

.banner-wrap-competition .banner-buttons button {
    padding: 0.75rem 2rem;
    border: none;
    cursor: pointer;
}

.banner-wrap-competition .banner-buttons .btn-dark {
    background-color: #00D49A;
    color: #242424;
    border: none;
    padding: 18px 48px;
    text-align: center;
    font-size: 18.333px;
    font-style: normal;
    font-weight: 800;
    line-height: 13.333px;
}

.banner-wrap-competition .banner-buttons .btn-dark img {
    height: 24px;
}

.banner-wrap-competition .banner-buttons .btn-light {
    background-color: transparent;
    color: #06EFC5;
    border: 1px solid transparent;
    text-align: center;
    font-size: 18.333px;
    font-style: normal;
    font-weight: 800;
    line-height: 13.333px;
}

.banner-wrap-competition .banner-buttons .btn-light img {
    height: 24px;
}

.banner-wrap-competition .banner-right-img img {
    width: unset;
    height: unset;
}

.launching-date-wrap {
    padding: 91px 90px 77px;
    background-color: #150924;
}

.launching-date-wrap .top-button a {
    color: #150924 !important;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 33px;
    background-color: #F495D3;
    border-radius: 40px;
    text-decoration: none;
    padding: 5px 17px;
}

.launching-date-wrap .top-button a:hover {
    text-decoration: none !important;
}

.launching-date-wrap .description {
    color: #FFF;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px;
    padding: 32px 0px 43px;
}

.launching-date-wrap .bottom-btn a {
    color: var(--Balck, #242424);
    text-align: center;
    font-size: 18.333px;
    font-style: normal;
    font-weight: 800;
    line-height: 13.333px;
    background-color: #06EFC5;
    text-decoration: none;
    color: black !important;
    padding: 18px 80px;
}

.launching-date-wrap .bottom-button a:hover {
    text-decoration: none !important;
}

.launching-date-wrap a {
    text-decoration: none !important
}

.launching-date-wrap .bottom-description {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    padding-top: 40px;
}

.launching-date-wrap .right-img img {
    height: 500px;

}


@media (max-width: 1024px) {
    .banner-wrap-competition .banner-right-img img{
        width: 100%;
    }
}

@media (max-width: 768px) {
    .banner-wrap-competition {
        padding: 30px;
    }

    .banner-wrap-competition .banner-right-img {
        display: block;
    }

    .banner-wrap-competition .banner-content {
        padding-left: 0px;
    }

    .banner-wrap-competition .banner-content .title {
        font-size: 25px;
        line-height: 39px;
    }

    .banner-wrap-competition .banner-content .sub-description {
        font-size: 20px;
        line-height: 34px;
        text-align: left;
    }

    .banner-wrap-competition .banner-content .description {
        font-size: 15px;
        font-weight: 400;
        line-height: 19px;
        text-align: left;
    }

    .banner-wrap-competition .banner-right-img img {
        width: 100%;
    }

    .banner-wrap-competition .banner-buttons {
        flex-direction: column;
        gap: 0.5rem;
        display: none !important;
    }

    .launching-date-wrap {
        padding: 50px 0px 70px;
        text-align: center;
    }

    .launching-date-wrap .top-button a {
        font-size: 15px;
        line-height: 33px;
    }

    .launching-date-wrap .description {
        font-size: 15px;
        line-height: 29px;
        padding-top: 20px;
    }

    .launching-date-wrap .bottom-description {
        padding: 50px 0px 40px;
        font-size: 15px;
        line-height: 33px;
    }

    .launching-date-wrap .right-img img {
        width: 100%;
        height: 100%;
    }
}