/* General styles */
.web-page-header {
  display: flex;
  justify-content: space-between;
  padding: 37px 100px 10px 50px;
  background-color: #150924;
  align-items: center;
  border-bottom: 1px solid #dcd5e54a;
}

.web-page-header .logo img {
  width: 92px;
}

.web-page-header .nav {
  display: flex;
  align-items: center;
}

.web-page-header .nav-links {
  display: flex;
  list-style: none;
  margin-bottom: 0px;
}

.web-page-header .nav-links li {
  margin-right: 24px;
  opacity: 0.5;
}

.web-page-header .nav-links li a {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-decoration: none;
}

.web-page-header .nav-links li.active {
  opacity: 1;
  position: relative;
  color: white;
}

.web-page-header .nav-links li.active::after {
  content: "";
  position: absolute;
  height: 4px;
  width: 100%;
  background-color: #9256e8;
  border-radius: 5px 5px 0px 0px;
  bottom: -23px;
  left: 0;
}

.web-page-header .nav-links li a:hover {
  text-decoration: none !important;
}

.web-page-header .auth-buttons {
  display: flex;
}

.web-page-header .auth-buttons button {
  border: none;
  cursor: pointer;
}

.web-page-header .auth-buttons button a {
  color: #242424 !important;
  text-decoration: none !important;
}

.web-page-header .auth-buttons button a:hover {
  text-decoration: none !important;
}

.web-page-header .signup {
  background: #06efc5;
  color: #242424;
  text-align: center;
  font-size: 18.333px;
  font-style: normal;
  font-weight: 800;
  line-height: 13.333px;
  padding: 18px 29.5px;
}

.web-page-header .login {
  background: #06efc5;
  color: #242424;
  text-align: center;
  font-size: 18.333px;
  font-style: normal;
  font-weight: 800;
  line-height: 13.333px;
  padding: 18px 29.5px;
  margin-right: 22px;
}

/* Mobile styles */
@media (max-width: 850px) {
  .web-page-header {
    padding: 20px;
  }

  .web-page-header .nav {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #150924;
    position: absolute;
    top: 70px;
    left: 0;
    text-align: center;
  }

  .web-page-header .nav.open {
    display: flex;
    z-index: 2;
    background-color: #150924;
  }

  .web-page-header .nav-links {
    flex-direction: column;
    width: 100%;
  }

  .web-page-header .nav-links li {
    margin: 10px 0;
    text-align: left;
    padding-left: 20px;
  }

  .web-page-header .nav-links li a {
    color: white;
    font-size: 16px;
    opacity: 1;
  }

  .web-page-header .auth-buttons {
    margin-top: 20px;
    width: 100%;
    background-color: #150924;
    padding-left: 20px;
    padding-bottom: 50px;
  }

  .web-page-header .auth-buttons button {
    margin-bottom: 10px;
    color: white;
  }

  .web-page-header .menu-icon {
    display: block;
    cursor: pointer;
  }

  .web-page-header .menu-icon img {
    width: 30px;
  }
}

@media (max-width: 1200px) {
  .web-page-header .nav-links li {
    margin-right: 10px;
  }

  .web-page-header .login {
    padding: 18px 12px;
  }

  .web-page-header .signup {
    padding: 18px 12px;
  }
}

@media (max-width: 1024px) {
  .web-page-header {
    padding: 30px 20px;
    padding-bottom: 11px;
  }
}

@media (min-width: 850px) {
  .web-page-header .menu-icon {
    display: none;
  }
}