.aboutSection4 {
    background: #150924;
    color: white;
    padding: 100px;
    overflow: hidden;
}

.aboutSection4 .blog-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 65px;
    align-items: center;
}

.aboutSection4 .blog-header .heading {
    font-size: 39px;
    font-weight: 700;
    line-height: 75px;
    color: white;
    width: unset;
    padding: unset;

}

.blog-header .all-btn a {
    background-color: #06EFC5;
    color: #242424;
    text-align: center;
    font-size: 18.333px;
    font-style: normal;
    font-weight: 800;
    line-height: 13.333px;
    padding: 18px 52px;
    display: block;
    text-decoration: none;
}

.blog-header .all-btn a:hover {
    text-decoration: none !important;
}

.all-btn-mob {
    display: none;
}

.aboutSection4 .card-wrap {
    width: auto;
    /* height: 280px; */
    /* background-color: #303030; */
    /* padding: 40px 26px; */
    background: rgba(255, 255, 255, 0.05);
    margin-right: 40px;
}

.aboutSection4 .card-wrap .image img {
    width: 100%;

}

.aboutSection4 .card-wrap .card-content-wrap {
    padding: 30px 22px;
}

.aboutSection4 .card-wrap .card-content-wrap a:hover {
    text-decoration: none !important;
}

.aboutSection4 .card-wrap .card-content-wrap .title {
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 30.5px;
    margin-bottom: 0px;
    font-family: "Inter", sans-serif;
    text-align: left;
}

.aboutSection4 .card-wrap .card-content-wrap .blog-date {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    opacity: 0.4;
}

.aboutSection4 .card-wrap .card-content-wrap .description {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.aboutSection4 .card-wrap .card-content-wrap .read-btn {
    color: #06EFC5;
    font-style: normal;
    font-weight: 800;
    line-height: 13.333px;
    display: flex;
    align-items: center;
}

.aboutSection4 .card-wrap .read-btn img {
    height: 24px;
}

.aboutSection4 .slick-list {
    overflow: visible;
}

.aboutSection4 .slick-prev,
.slick-next {
    top: 42%;
}

.aboutSection4 .slider-container {
    width: 100%;
    display: block;
}

.aboutSection4 .slick-prev:before {
    display: none;
}

.aboutSection4 .slick-next:before {
    display: none;
}

.aboutSection4 .slick-prev {
    z-index: 1;
    left: 0;
}

.aboutSection4 .slick-next {
    z-index: 1;
    right: 45px;
}

.blog-card-mob {
    display: none;
}

@media (max-width: 768px) {
    .aboutSection4 .blog-header {
        display: unset;
    }

    .aboutSection4 .blog-header .heading {
        font-size: 25px;
        line-height: 75px;
        text-align: center;
    }

    .blog-header .all-btn {
        display: none;
    }

    .all-btn-mob {
        display: block;
    }

    .all-btn-mob a {
        background-color: #06EFC5;
        color: #242424;
        text-align: center;
        font-size: 18.333px;
        font-style: normal;
        font-weight: 800;
        line-height: 13.333px;
        padding: 18px 52px;
        display: block;
        text-decoration: none;
    }

    .all-btn-mob a:hover {
        text-decoration: none;
    }

    .aboutSection4 {
        padding: 25px;
    }

    .aboutSection4 .slider-container {
        display: none;
    }

    .aboutSection4 .blog-header {
        margin-bottom: 24px;
    }

    .blog-card-mob {
        display: block;
    }

    .blog-card-mob .card-wrap-blog {
        width: 100%;
        background: rgba(255, 255, 255, 0.05);
        margin-bottom: 39px;
    }

    .blog-card-mob .card-wrap-blog .image img {
        width: 100%;

    }

    .blog-card-mob .card-wrap-blog .card-content-wrap {
        padding: 30px 22px;
    }

    .blog-card-mob .card-wrap-blog .card-content-wrap .title {
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 39px;
        margin-bottom: 0px;
        text-align: left;
        font-family: "Inter", sans-serif;
    }

    .blog-card-mob .card-wrap-blog .card-content-wrap .blog-date {
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        opacity: 0.4;
    }

    .blog-card-mob .card-wrap-blog .card-content-wrap .description {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        text-align: left;
    }

    .blog-card-mob .card-wrap-blog .card-content-wrap .read-btn {
        color: #06EFC5;
        font-style: normal;
        font-weight: 800;
        line-height: 13.333px;
        display: flex;
        align-items: center;
    }

    .blog-card-mob .card-wrap-blog .read-btn img {
        height: 24px;
    }
}