.interview-simulator {
    min-height: 100vh;
    background-color: #0F0125;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 0px;
}

.interview-simulator .user-img-wrap {
    min-width: 650px;
    margin: 0 auto;
    justify-self: center;
    align-items: center;
}

.interview-simulator .profile-sec-wrap {
    justify-content: space-between;
    min-width: 750px;
    margin: 0 auto;
    position: relative;
}

.profile-sec-wrap .arrow-img-wrap {
    text-align: center;
    position: absolute;
    top: 15%;
    display: block;
    right: -65%;
}

.interview-simulator .logo-img {
    max-width: 150px;
    /* margin-bottom: 20px; */
}

.interview-simulator .heading {
    font-size: 26px;
    font-weight: 700;
    line-height: 45px;
    color: white;
    text-align: center;
    margin-bottom: 10px;
    width: unset;
    font-family: "Inter", sans-serif;
}

.interview-simulator .user-name {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #ffff;
    font-family: "Inter", sans-serif;
    /* padding-top: 20px; */
}

.interview-simulator .user-name span {
    font-weight: 700;
}

.interview-simulator .icon-title {
    font-size: 12px;
    font-weight: 400;
    line-height: 35px;
    font-family: "Inter", sans-serif;
}

.interview-simulator .profile-section {
    margin-bottom: 10px;
    position: relative;
}

.interview-simulator .profile-section .caller-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    font-family: "Inter", sans-serif;
    /* padding-top: 20px; */
}

.interview-simulator .profile-section .caller-name {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    font-family: "Inter", sans-serif;
    text-wrap: nowrap;
}

.interview-simulator .profile-img {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    margin-bottom: 5px;
}

.interview-simulator .icons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 10px;
}

.interview-simulator .icon-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.interview-simulator .icon-img {
    width: 40px;
    height: 40px;
}

.interview-simulator .bottom-content {
    justify-content: center;
    overflow: hidden;
}

.interview-simulator .bottom-content .description {
    max-width: 582px;
    margin: 0 auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    padding-bottom: 15px;
    font-family: "Inter", sans-serif;
}

.interview-simulator .bottom-content .prepare-button {
    background-color: #06EFC5;
    padding: 18px 59px;
    font-size: 18.33px;
    font-weight: 800;
    line-height: 13.33px;
    color: #242424;
    cursor: pointer;
    border-radius: 0px;
    font-family: "Inter", sans-serif;
}

.interview-simulator .bottom-content .prepare-button:focus {
    box-shadow: none;
}

.interview-simulator .bottom-content .bottom-line {
    font-weight: 400;
    line-height: 20px;
    font-size: 16px;
    color: #ffff;
    padding: 18px 54px;
    background-color: #1e102d;
    width: max-content;
    margin: 28px auto 0px;
    font-family: "Inter", sans-serif;
}

@media(max-width: 1020px) {
    .interview-simulator {
        padding: 20px 50px;
    }

    .interview-simulator .heading {
        line-height: 40px;
    }

    .interview-simulator .profile-section {
        margin-bottom: 10px;
    }

    .interview-simulator .bottom-content .bottom-line {
        margin: 0px auto;
        background-color: transparent;
    }
}

@media(max-width: 767px) {
    .interview-simulator {
        padding: 20px 0px;
    }

    .profile-sec-wrap {
        min-width: unset;
    }

    .interview-simulator .profile-sec-wrap {
        min-width: unset;
    }

    .interview-simulator .logo-img {
        margin-bottom: 0px;
    }

    .interview-simulator .heading {
        line-height: 24px;
        font-size: 16px;
        max-width: 320px;
        margin: 0 auto;
        padding: 10px 0px;
        font-family: "Inter", sans-serif;
    }

    .interview-simulator .profile-img {
        width: 100px;
        height: 100px;
    }

    .interview-simulator .profile-section .caller-title {
        font-size: 14px;
        line-height: 22px;
    }

    .interview-simulator .profile-section .caller-name {
        font-size: 14px;
        line-height: 22px;
    }

    .interview-simulator .bottom-content .description {
        font-size: 12px;
        line-height: 20px;
        opacity: 50%;
    }

    .interview-simulator .bottom-content .bottom-line {
        font-size: 10px;
        background-color: transparent;
        margin: 5px auto 0px;
    }

    .interview-simulator .bottom-content .prepare-button {
        font-size: 14px;
        font-weight: 800;
        line-height: 13.33px;
        width: 100%;
    }

    .profile-sec-wrap .arrow-img-wrap {
        display: none;
    }
}