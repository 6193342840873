.pitching-mode-wrapper {
    background-color: rgba(15, 1, 37, 1);
    color: #fff;
    min-height: 100vh;
    padding: 40px 52px;
}

.pitching-mode-wrapper .header {
    margin-bottom: 30px;
    display: unset;
    border-bottom: none;
    background-color: transparent;
}

.pitching-mode-wrapper .pitching-header-wrap .logo {
    max-width: 100px;
    display: block;
    margin-bottom: 5px;
}

.pitching-mode-wrapper .pitching-details {
    margin-top: 5px;
}

.pitching-mode-wrapper .pitching-header-wrap .title {
    font-size: 18px;
    font-weight: 400;
    line-height: 34px;
    text-align: left;
    display: block;
    font-family: "Inter", sans-serif;
}

.pitching-mode-wrapper .pitching-details .caller-details {
    font-size: 18px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    line-height: 34px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    display: flex;
}

.pitching-mode-wrapper .pitching-details .caller-details .dot {
    display: block;
}

.pitching-mode-wrapper .caller-details span {
    font-weight: 600;
}


.pitching-mode-top-content {
    display: flex;
    justify-content: space-between;
    padding: 0px 0px 10px;
    align-items: center;
}

.pitching-mode-top-content .status {
    font-size: 24px;
    font-weight: 600;
    line-height: 75px;
    color: #38bbf4;
    font-family: "Inter", sans-serif;
    color: #38bbf4;
}

.pitching-mode-top-content .start-pitching-button {
    background-color: #F84242;
    color: white;
    font-size: 18.33px;
    font-weight: 800;
    line-height: 13.33px;
    /* padding: 18px 50px; */
    border: none;
    cursor: pointer;
    width: 310px;
    height: 50px;
    font-family: "Inter", sans-serif;
}

.pitching-mode-bottom-content {
    display: flex;
    justify-content: center;
}

.pitching-mode-bottom-content .left-img-wrap {
    position: relative;
    width: 34%;
}

.pitching-mode-bottom-content .left-img-wrap img {
    width: 100%;
    height: 100%;
}

.pitching-mode-bottom-content .left-img-wrap .top-img-mic-icon {
    width: 40px;
    height: 40px;
}

.pitching-mode-bottom-content .left-img-wrap .top-img-mic-icon {
    position: absolute;
    top: 3%;
    right: 3%;
}

.pitching-mode-bottom-content .left-img-wrap .bottom-icon-wrap {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.81);
    height: 88px;
    width: 100%;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
}

.pitching-mode-bottom-content .left-img-wrap .bottom-icon-wrap .bottom-title {
    font-weight: 700;
    line-height: 75px;
    font-size: 16px;
    color: #000000;
    font-family: "Inter", sans-serif;
}

.pitching-mode-bottom-content .left-img-wrap .bottom-icon-wrap .bottom-title span {
    font-weight: 500;
}

.pitching-mode-bottom-content .left-img-wrap .bottom-icon-wrap .feature-icon-wrap {
    display: flex;
}

.pitching-mode-bottom-content .left-img-wrap .bottom-icon-wrap .feature-icon-wrap .icon-button {
    background-color: transparent;
    border: none;
}

.pitching-mode-bottom-content .left-img-wrap .bottom-icon-wrap .feature-icon-wrap .icn-2 {
    padding-left: 20px;
}

.pitching-mode-bottom-content .right-img-wrap {
    width: 40%;
}

.pitching-mode-bottom-content .right-img-wrap img {
    width: 100%;
}


.pitching-mode-bottom-content .right-img-wrap .pitch-user-card {
    position: relative;
}

.pitching-mode-bottom-content .right-img-wrap .pitch-user-card .bottom-title {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.81);
    height: 44px;
    width: 100%;
    bottom: 0;
    font-weight: 700;
    line-height: 37.5px;
    font-size: 16px;
    color: #000000;
    padding-left: 34px;
    font-family: "Inter", sans-serif;
}

.pitching-mode-bottom-content .right-img-wrap .pitch-right-box .row {
    margin-left: 0px;
    margin-right: 0px;
}

.pitching-mode-bottom-content .right-img-wrap .pitch-right-box .row .col-md-6 {
    padding-left: 0;
    padding-right: 0;
}

.pitching-mode-bottom-content .top-title-mob {
    display: none;
}

.pitching-mode-bottom-wrap {
    display: none;
}

@media (max-width: 1650px) {
    .pitching-mode-wrapper {
        padding: 20px 52px;
    }

    .pitching-mode-wrapper .pitching-header-wrap .logo {
        margin-bottom: 0px;
    }

    .pitching-mode-wrapper .pitching-details {
        margin-top: 0px;
    }

    .pitching-mode-wrapper .pitching-details .caller-details {
        padding-bottom: 8px;
    }

    .pitching-mode-top-content {
        padding: 10px 0px;
    }

    .pitching-mode-top-content .status {
        line-height: 50px;
    }

    .pitching-mode-bottom-content .left-img-wrap {
        width: 29%;
    }

    .pitching-mode-bottom-content .left-img-wrap .caller-img {
        width: 400px;
        height: 415px;
    }

    .pitching-mode-bottom-content .right-img-wrap {
        width: 34.5%;
    }

    .pitching-mode-bottom-content .left-img-wrap .icon-img {
        width: 40px;
    }

    .pitching-mode-bottom-content .left-img-wrap .bottom-icon-wrap {
        padding: 0px 20px;
    }
}

@media (max-width: 768px) {
    .pitching-mode-wrapper {
        padding: 32px 20px;
    }

    .pitching-mode-wrapper .pitching-header-wrap .logo {
        margin: 0 auto;
    }

    .pitching-mode-top-content {
        display: none;
    }

    .pitching-mode-bottom-content {
        flex-direction: column;
        margin-top: 15px;
    }

    .pitching-mode-wrapper .pitching-header-wrap .title {
        display: none;
    }

    .pitching-mode-wrapper .pitching-details .caller-details {
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        flex-direction: column;
        border-bottom: none;
    }

    .pitching-mode-wrapper .pitching-details .caller-details .dot {
        display: none;
    }

    .pitching-mode-wrapper .pitching-details {
        padding-top: 15px;
    }

    .pitching-mode-bottom-content .left-img-wrap {
        width: 100%;
    }

    .pitching-mode-bottom-content .left-img-wrap .top-title-mob {
        display: block;
        background-color: #ffffff;
        color: #38BBF4;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        border-radius: 10px;
        padding: 6px 10px;
        position: absolute;
        left: 5%;
        top: 5%;
    }

    .pitching-mode-bottom-content .left-img-wrap .top-img-mic-icon {
        top: 4%;
    }

    .pitching-mode-bottom-content .left-img-wrap .top-title-mob img {
        width: 16px;
        height: 16px;
    }

    .pitching-mode-bottom-content .left-img-wrap .caller-img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
    }

    .pitching-mode-bottom-content .left-img-wrap .bottom-icon-wrap {
        height: 42px;
        border-radius: 0px 0px 10px 10px;
        justify-content: flex-start;
        padding-left: 10px;
    }

    .pitching-mode-bottom-content .left-img-wrap .bottom-icon-wrap .bottom-title {
        font-size: 12px;
        line-height: 20px;
        font-weight: 700;

    }

    .pitching-mode-bottom-content .left-img-wrap .bottom-icon-wrap .bottom-title span {
        font-weight: 500;
    }

    .pitching-mode-bottom-content .left-img-wrap .bottom-icon-wrap .feature-icon-wrap {
        display: none;
    }

    .pitching-mode-bottom-content .right-img-wrap {
        width: 100%;
    }

    .pitching-mode-bottom-content .right-img-wrap {
        margin-top: 20px;
    }

    .pitching-mode-bottom-content .right-img-wrap img {
        border-radius: 10px;
    }


    .pitching-mode-bottom-content .right-img-wrap .bottom-title {
        font-size: 12px;
        line-height: 20px;
        font-weight: 700;
        height: 42px;
        border-radius: 0px 0px 10px 10px;
        padding-left: 10px;
        display: flex;
        align-items: center;
        font-family: "Inter", sans-serif;
    }

    .pitching-mode-bottom-content .right-img-wrap .bottom-title span {
        font-weight: 500;
    }

    .pitching-mode-bottom-content .right-img-wrap .pitch-user-card {
        margin-right: 11px;
        margin-top: 11px;
    }

    .pitching-mode-bottom-wrap {
        display: flex;
        justify-content: center;
        padding-top: 22px;
    }

    .pitching-mode-bottom-wrap .mid-icn {
        padding: 0px 20px;
    }

    .pitching-mode-bottom-content .right-img-wrap .pitch-user-card .bottom-title {
        display: block;
        text-align: center;
        padding-left: 0px;
    }

}

@media (max-width: 400px) {
    .pitching-mode-bottom-content .left-img-wrap .top-img-mic-icon {
        width: 30px;
        height: 30px;
    }

    .pitching-mode-bottom-content .left-img-wrap .top-img-mic-icon {
        top: 5%;
    }

    .pitching-mode-bottom-content .right-img-wrap .pitch-user-card .bottom-title {
        font-size: 14px;
    }
}