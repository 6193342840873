.pay_box{
    width: 350px;
    /* height: 234px; */
    flex-shrink: 0;
    background: linear-gradient(0deg, #100027, #100027),linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)),
linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
    border-radius:16px;
}
.pay_title{
    color: var(--White, #FFF);
    text-align: center;
    font-family: Urbanist;
    margin:10px 0px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1px;
}
.pay_desc{
    color: var(--White, #FFF);
    text-align: center;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
}



.card_details_btn{
    border-radius: 10px;
    background: #06EFC5;
    box-shadow: 0px 2px 24px 0px rgba(16, 155, 255, 0.24);
    width:50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    color:black;
    cursor: pointer;
}
.card_details_text{
    color: #100027;
    text-align: center;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.165px;
}

.outer_container{
background: rgba(24, 0, 57, 0.75);
backdrop-filter: blur(0.5px); 
}

.btn_pay_continue{
    border-radius: 10px;
    border: 1px solid #06EFC5;
    background: linear-gradient(89deg, #242424 -261.85%, rgba(36, 36, 36, 0.00) 98.43%);
    width:50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    color:black;
    cursor: pointer;
}

.continue_text{
    color:white;
    text-align: center;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.165px;
}