.contact-container {
    display: flex;
    flex-direction: row;
    /* Use row direction for larger screens */
    height: 100vh;
    background-color: #1a0d2d;
    padding: 0;
    margin: 0;
    color: white;
}

.contact-container .form-section {
    flex: 1;
    padding: 4rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    margin-right: 0;
}

.contact-container .title {
    color: #FFF;
    font-size: 56px;
    font-weight: 700;
    line-height: 68px;
    text-align: left;
    margin-bottom: 16px;
    font-family: "Inter", sans-serif;
}

.contact-container .description {
    color: #FFF;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    margin-bottom: 27px;
}

.contact-container .form-section form {
    display: flex;
    flex-direction: column;
}

.contact-container .input-group {
    margin-bottom: 34px;
    background-color: #231b2e;
    padding: 0px 13px;
}

.contact-container .input-group label {
    display: block;
    color: #FFF;
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: -0.36px;
    opacity: 0.5;
    padding-top: 8px;
    margin-bottom: 0px;
}

.contact-container .input-group input,
.contact-container .input-group textarea {
    width: 100%;
    padding-bottom: 7px;
    border: none;
    background-color: transparent;
    border-radius: 5px;
    outline: none;
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.42px;
    opacity: 1;
}

.contact-container .input-group:focus-within {
    border: 1px solid #9256E8;
    background: rgba(146, 86, 232, 0.11);
}

.contact-container .input-group input::placeholder,
.contact-container .input-group textarea::placeholder {
    color: #ffffff;
}

.contact-container .submit-btn {
    background-color: #00ffa3;
    padding: 18px 100px;
    border: none;
    cursor: pointer;
    align-self: flex-end;
    color: #242424;
    text-align: center;
    font-size: 18.333px;
    font-style: normal;
    font-weight: 800;
    line-height: 13.333px;
}

.contact-container .submit-btn span {
    margin-left: 0.5rem;
}

.contact-container .image-section {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-container .image-section img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
        height: unset;
    }

    .contact-container .form-section {
        margin-right: 0;
        padding: 2rem 1rem;
    }

    .contact-container .title {
        font-size: 36px;
        line-height: 44px;
        text-align: center;
    }

    .contact-container .description {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
        text-align: center;
        padding-bottom: 20px;
    }

    .contact-container .input-group {
        margin-bottom: 24px;
        padding: 0 10px;
    }

    .contact-container .submit-btn {
        width: 100%;
        font-size: 1rem;
        padding: 0.8rem 2rem;
        align-self: center;
    }

    .contact-container .image-section img {
        width: 100%;
        height: auto;
    }
}

@media (max-width: 480px) {
    .contact-container .title {
        font-size: 28px;
        line-height: 36px;
    }

    .contact-container .description {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 12px;
    }

    .contact-container .input-group {
        margin-bottom: 20px;
        padding: 0 8px;
    }

    .contact-container .submit-btn {
        font-size: 0.9rem;
        padding: 0.6rem 1.5rem;
    }
}