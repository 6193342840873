.trainer-mode-wrapper {
    background-color: rgba(15, 1, 37, 1);
    color: #fff;
    min-height: 100vh;
    padding: 50px;
}

.trainer-mode-wrapper .trainer-mode-header {
    margin-bottom: 30px;
    display: unset;
    border-bottom: none;
    background-color: transparent;
}

.trainer-mode-wrapper .trainer-mode-header .header-wrap {
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.trainer-mode-wrapper .header-wrap .header-right-wrap .green-trainer-button {
    background-color: #06efc5;
    color: black;
    font-size: 18.33px;
    font-weight: 800;
    line-height: 13.33px;
    padding: 18px 50px;
    border: none;
    cursor: pointer;
    gap: 10px;
    display: inline-block;
    font-family: "Inter", sans-serif;
}

.trainer-mode-wrapper .green-trainer-button-mob {
    background-color: #06efc5;
    color: black;
    font-size: 14px;
    font-weight: 800;
    line-height: 13.33px;
    padding: 18px 20px;
    border: none;
    cursor: pointer;
    gap: 10px;
    display: none;
    border-radius: 50px;
    font-family: "Inter", sans-serif;
}

.trainer-mode-wrapper .header-wrap .headre-left-wrap .logo {
    max-width: 100px;
    display: block;
    margin-bottom: 10px;
}

.trainer-mode-wrapper .interview-details {
    margin-top: 10px;
}

.trainer-mode-wrapper .header-wrap .title {
    font-size: 18px;
    font-weight: 400;
    line-height: 34px;
    text-align: left;
    display: block;
    font-family: "Inter", sans-serif;
}

.trainer-mode-wrapper .trainer-details .caller-details {
    font-size: 18px;
    font-weight: 400;
    line-height: 34px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    font-family: "Inter", sans-serif;
    display: flex;
    padding-bottom: 16px;
}

.trainer-mode-wrapper .trainer-details .caller-details .dot {
    display: block;
}

.trainer-mode-wrapper .caller-details span {
    font-weight: 600;
}

.trainer-mode-top-content {
    display: flex;
    justify-content: space-between;
    padding: 34px 0px 20px;
}

.trainer-mode-top-content .start-trainer-button {
    background-color: #f84242;
    color: white;
    font-size: 18.33px;
    font-weight: 800;
    line-height: 13.33px;
    padding: 18px 50px;
    border: none;
    cursor: pointer;
    font-family: "Inter", sans-serif;
}

.trainer-mode-top-content .start-trainer-button img {
    width: 20px;
    height: 20px;
}

.trainer-mode-bottom-content {
    display: flex;
    margin-top: 20px;
    justify-content: center;
}

.trainer-mode-bottom-content .left-img-wrap {
    position: relative;
    /* width: 50%; */
}

.trainer-mode-bottom-content .left-img-wrap .caller-img {
    width: 100%;
}

.trainer-mode-bottom-content .right-img-wrap .caller-img {
    width: 100%;
}

.trainer-mode-bottom-content .left-img-wrap .top-img-mic-icon {
    position: absolute;
    top: 3%;
    right: 3%;
}

.trainer-mode-bottom-content .left-img-wrap .bottom-icon-wrap {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.81);
    height: 88px;
    width: 100%;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px 0px 34px;
}

.trainer-mode-bottom-content .left-img-wrap .bottom-icon-wrap .bottom-title {
    font-weight: 700;
    /* line-height: 75px; */
    font-size: 22px;
    color: #000000;
    font-family: "Inter", sans-serif;
}

.trainer-mode-bottom-content .left-img-wrap .bottom-icon-wrap .bottom-title span {
    font-weight: 500;
}

.trainer-mode-bottom-content .left-img-wrap .bottom-icon-wrap .feature-icon-wrap {
    display: flex;
}

.trainer-mode-bottom-content .left-img-wrap .bottom-icon-wrap .feature-icon-wrap .icon-button {
    background-color: transparent;
    border: none;
}

.trainer-mode-bottom-content .left-img-wrap .bottom-icon-wrap .feature-icon-wrap .icn-2 {
    padding-left: 20px;
}

.trainer-mode-bottom-content .right-img-wrap {
    position: relative;
    /* width: 50%; */
}

.trainer-mode-bottom-content .right-img-wrap .thinking-btn {
    position: absolute;
    right: 5%;
    top: 5%;
    display: flex;
    background-color: #fc9403;
    padding: 6px 10px;
    align-items: center;
}

.trainer-mode-bottom-content .right-img-wrap .thinking-btn img {
    width: 22px;
    height: 22px;
}

.trainer-mode-bottom-content .right-img-wrap .thinking-btn span {
    color: white;
    font-size: 16px;
    line-height: 34px;
    font-weight: 600;
    padding: 0px 10px;
    font-family: "Inter", sans-serif;
}

.trainer-mode-bottom-content .right-img-wrap .ready-btn {
    position: absolute;
    right: 5%;
    top: 15%;
    display: flex;
    background-color: #9256e8;
    padding: 6px 10px;
    align-items: center;
}

.trainer-mode-bottom-content .right-img-wrap .ready-btn img {
    width: 22px;
    height: 22px;
}

.trainer-mode-bottom-content .right-img-wrap .ready-btn span {
    color: white;
    font-size: 16px;
    line-height: 34px;
    font-weight: 600;
    padding: 0px 10px;
    font-family: "Inter", sans-serif;
}

.trainer-mode-bottom-content .right-img-wrap .disable-btn {
    position: absolute;
    right: 5%;
    top: 25%;
    color: #242424;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    background-color: #ffffff;
    border-radius: 10px 0px 10px 10px;
    padding: 10px 60px 10px 20px;
    font-family: "Inter", sans-serif;
}

.trainer-mode-bottom-content .right-img-wrap .enable-btn {
    position: absolute;
    right: 5%;
    top: 35%;
    color: #242424;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    background-color: #ffffff;
    border-radius: 10px 0px 10px 10px;
    padding: 10px 60px 10px 20px;
    font-family: "Inter", sans-serif;
}

.trainer-mode-bottom-content .right-img-wrap img {
    width: 100%;
    height: 100%;
}

.trainer-mode-bottom-content .right-img-wrap .bottom-title span {
    font-weight: 500;
}

.trainer-mode-bottom-content .right-img-wrap .bottom-title {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.81);
    height: 88px;
    width: 100%;
    bottom: 0;
    font-weight: 700;
    /* line-height: 75px; */
    font-size: 22px;
    color: #000000;
    padding-left: 34px;
    font-family: "Inter", sans-serif;
    display: flex;
    align-items: center;
    gap: 5px;
}

.trainer-mode-bottom-content .top-title-mob {
    display: none;
}

.trainer-mode-bottom-wrap {
    display: none;
}

@media (max-width: 1650px) {
    .trainer-mode-wrapper {
        padding: 20px 50px;
    }

    .trainer-mode-top-content {
        padding: 10px 0px;
    }

    .trainer-mode-bottom-content {
        margin-top: 10px;
    }

    .trainer-mode-bottom-content .left-img-wrap {
        width: unset;
    }

    .trainer-mode-bottom-content .right-img-wrap {
        width: unset;
    }

    .trainer-mode-bottom-content .left-img-wrap .caller-img {
        width: 400px;
    }

    .trainer-mode-bottom-content .right-img-wrap .caller-img {
        width: 400px;
    }

    .trainer-mode-bottom-content .left-img-wrap .bottom-icon-wrap .bottom-title {
        font-size: 16px;
    }

    .trainer-mode-bottom-content .left-img-wrap .icon-img {
        width: 40px;
    }

    .trainer-mode-bottom-content .left-img-wrap .bottom-icon-wrap {
        padding: 0px 15px;
    }

    .trainer-mode-bottom-content .right-img-wrap .bottom-title {
        padding-left: 12px;
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .trainer-mode-wrapper {
        padding: 32px 20px;
    }

    .trainer-mode-wrapper .header-wrap .logo {
        margin: 0 auto;
    }

    .trainer-mode-wrapper .trainer-mode-header .header-wrap {
        display: unset;
        text-align: center;
        padding: 20px 0px;
    }

    .trainer-mode-bottom-content .left-img-wrap .top-img-mic-icon img {
        height: 40px;
        width: 40px;
    }

    .trainer-mode-wrapper .header-wrap .header-right-wrap .green-trainer-button {
        display: none;
    }

    .trainer-mode-wrapper .green-trainer-button-mob {
        display: flex;
        align-items: center;
    }

    .trainer-mode-top-content {
        display: none;
    }

    .trainer-mode-bottom-content {
        flex-direction: column;
        margin-top: 15px;
    }

    .trainer-mode-wrapper .header-wrap .title {
        display: none;
    }

    .trainer-mode-wrapper .trainer-details .caller-details {
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        max-width: 300px;
        margin: 0 auto;
        flex-direction: column;
        border-bottom: none;
        padding: 15px 0px;
    }

    .trainer-mode-wrapper .trainer-details .caller-details .dot {
        display: none;
    }

    .trainer-mode-bottom-content .left-img-wrap {
        width: 100%;
    }

    .trainer-mode-bottom-content .left-img-wrap .top-title-mob {
        display: block;
        background-color: #ffffff;
        color: #38bbf4;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        border-radius: 10px;
        padding: 6px 10px;
        position: absolute;
        left: 5%;
        top: 5%;
        font-family: "Inter", sans-serif;
    }

    .trainer-mode-bottom-content .left-img-wrap .top-img-mic-icon {
        top: 4%;
    }

    .trainer-mode-bottom-content .left-img-wrap .top-title-mob img {
        width: 16px;
        height: 16px;
    }

    .trainer-mode-bottom-content .left-img-wrap .caller-img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
    }

    .trainer-mode-bottom-content .left-img-wrap .bottom-icon-wrap {
        height: 42px;
        border-radius: 0px 0px 10px 10px;
        justify-content: flex-start;
        padding-left: 10px;
    }

    .trainer-mode-bottom-content .left-img-wrap .bottom-icon-wrap .bottom-title {
        font-size: 12px;
        line-height: 20px;
        font-weight: 700;
    }

    .trainer-mode-bottom-content .left-img-wrap .bottom-icon-wrap .bottom-title span {
        font-weight: 500;
    }

    .trainer-mode-bottom-content .left-img-wrap .bottom-icon-wrap .feature-icon-wrap {
        display: none;
    }

    .trainer-mode-bottom-content .right-img-wrap {
        margin-top: 20px;
        width: 100%;
    }

    .trainer-mode-bottom-content .right-img-wrap img {
        border-radius: 10px;
    }

    .trainer-mode-bottom-content .right-img-wrap .bottom-title {
        font-size: 12px;
        line-height: 20px;
        font-weight: 700;
        height: 42px;
        border-radius: 0px 0px 10px 10px;
        padding-left: 10px;
        display: flex;
        align-items: center;
        font-family: "Inter", sans-serif;
    }

    .trainer-mode-bottom-content .right-img-wrap .bottom-title span {
        font-weight: 500;
    }

    .trainer-mode-bottom-content .right-img-wrap .caller-img {
        width: 100%;
        height: 100%;
    }

    .trainer-mode-bottom-wrap {
        display: flex;
        justify-content: center;
        padding-top: 22px;
    }

    .trainer-mode-bottom-wrap img {
        height: 52px;
    }

    .trainer-mode-bottom-wrap .green-trainer-button-mob img {
        height: 20px;
        width: 20px;
    }

    .trainer-mode-bottom-wrap .mid-icn {
        padding: 0px 20px;
    }
}

@media (max-width: 400px) {
    .trainer-mode-bottom-content .left-img-wrap .top-img-mic-icon {
        top: 5%;
    }

    .trainer-mode-wrapper .green-trainer-button-mob {
        padding: 15px 12px;
    }

    .trainer-mode-bottom-content .left-img-wrap .top-img-mic-icon img {
        width: 30px;
        height: 30px;
    }
}
