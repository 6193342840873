.member-section {
    padding: 78px 100px 145px;
}

.right-content-wrap {
    max-width: 630px;
}

.left-content-wrap {
    max-width: 630px;
}

.member-section .title {
    font-size: 46px;
    margin-bottom: 1rem;
    font-weight: 600;
    line-height: 58px;
    color: #242424;
    text-align: left;
    margin-top: 20px;
    font-family: "Inter", sans-serif;
}

.member-section .title span {
    font-weight: 800;
}

.member-section .description {
    font-size: 22px;
    line-height: 33px;
    font-weight: 400;
    color: #242424;
    margin: 24px 0px;
}

.member-section a {
    text-decoration: none;
}

.member-section a:hover {
    text-decoration: none !important;
}

.green-btn {
    background-color: #06EFC5;
    color: #242424;
    font-size: 18.33px;
    font-weight: 800;
    line-height: 13.33px;
    border: 1px solid transparent;
    padding: 18px 32px;
    display: block;
}

.green-btn-mob {
    display: none;
}

.pink-btn {
    background-color: #F495D3;
    border-radius: 49px;
    padding: 2px 15px;
    color: #ffffff;
    font-size: 22px;
    line-height: 33px;
    font-weight: 600;
    border: 1px solid transparent;
}

.blue-btn {
    background-color: #38BBF4;
    border-radius: 49px;
    padding: 2px 15px;
    color: #ffffff;
    font-size: 22px;
    line-height: 33px;
    font-weight: 600;
    border: 1px solid transparent;
}

.member-sec-wrap {
    margin-bottom: 100px;
}

@media (max-width: 768px) {
    .member-section {
        padding: 46px 25px;
    }

    .member-sec-wrap {
        flex-direction: column-reverse;
    }

    .member-section .title {
        font-size: 25px;
        line-height: 39px;
        margin-top: 20px;
        margin-bottom: 0px;
    }

    .member-section .description {
        font-size: 15px;
        line-height: 19px;
        padding: 20px 0px;
        margin: 0px;
    }

    .pink-btn {
        font-size: 12px;
        line-height: 33px;
    }

    .green-btn {
        display: none;
    }

    .green-btn-mob {
        display: block;
        background-color: #06EFC5;
        color: #242424;
        font-size: 15px;
        font-weight: 800;
        line-height: 13.33px;
        border: 1px solid transparent;
        padding: 18px 32px;
        width: 100%;
        margin: 50px 0px 10px;
    }
}