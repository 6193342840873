.trainer-pitching-wrapper {
    min-height: 100vh;
    background-color: #0F0125;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 100px 0px;
}

.trainer-pitching-wrapper .profile-sec-wrap {
    justify-content: space-between;
    min-width: 750px;
    margin: 0 auto;
    position: relative;
}

.trainer-pitching-wrapper .profile-sec-wrap .profile-image-wrap {
    text-align: center;
    position: absolute;
    top: 10%;
    display: block;
    right: -42%;
}

.trainer-pitching-wrapper .profile-sec-wrap .profile-image-wrap .mid-title {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
}

.trainer-pitching-wrapper .logo-img {
    max-width: 150px;
    margin-bottom: 20px;
}

.trainer-pitching-wrapper .heading {
    font-size: 26px;
    font-weight: 700;
    line-height: 75px;
    color: white;
    text-align: center;
    margin-bottom: 10px;
    width: unset;
}

.trainer-pitching-wrapper .sub-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding-bottom: 20px;
}

.trainer-pitching-wrapper .pitch-btn-pink {
    color: #242424;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    background-color: #F495D3;
    padding: 6px 20px;
    border-radius: 7px;
    margin-bottom: 30px;
}

.trainer-pitching-wrapper .user-name {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #ffff;
}

.trainer-pitching-wrapper .user-name span {
    font-weight: 700;
}

.trainer-pitching-wrapper .icon-title {
    font-size: 12px;
    font-weight: 400;
    line-height: 35px;
}

.trainer-pitching-wrapper .profile-section-trainer {
    margin-bottom: 40px;
    max-width: 280px;
    margin: 0 auto;
}

.trainer-pitching-wrapper .profile-section-trainer .pitching-user-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.trainer-pitching-wrapper .profile-section-trainer .pitching-user-wrap .user-name {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    padding: 24px 0px;

}


.trainer-pitching-wrapper .profile-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.trainer-pitching-wrapper .icons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 10px;
}

.trainer-pitching-wrapper .icon-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.trainer-pitching-wrapper .icon-img {
    width: 40px;
    height: 40px;
}

.trainer-pitching-wrapper .bottom-content {
    justify-content: center;
    overflow: hidden;
}

.trainer-pitching-wrapper .bottom-content .description {
    max-width: 582px;
    margin: 0 auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 35px;
    padding-bottom: 26px;
}

.trainer-pitching-wrapper .bottom-content .prepare-button {
    background-color: #06EFC5;
    /* padding: 18px 59px; */
    height: 50px;
    width: 310px;
    font-size: 18.33px;
    font-weight: 800;
    line-height: 13.33px;
    color: #242424;
    cursor: pointer;
    border-radius: 0px;
    display: inline-block;
}

.trainer-pitching-wrapper .bottom-content .prepare-button-mob {
    background-color: #06EFC5;
    padding: 18px 59px;
    font-size: 18.33px;
    font-weight: 800;
    line-height: 13.33px;
    color: #242424;
    cursor: pointer;
    border-radius: 0px;
    display: none;
}

.trainer-pitching-wrapper .bottom-content .prepare-button:focus {
    box-shadow: none;
}

.trainer-pitching-wrapper .bottom-content .bottom-line {
    font-weight: 400;
    line-height: 20px;
    font-size: 16px;
    color: #ffff;
    padding: 18px 54px;
    background-color: #1e102d;
    width: max-content;
    margin: 28px auto 0px;
}

.trainer-pitching-wrapper .profile-sec-wrap .mob-title-pitch {
    display: none;
}

@media(max-width: 1650px) {
    .trainer-pitching-wrapper {
        padding: 30px 0px 10px;
    }

    .trainer-pitching-wrapper .logo-img {
        margin-bottom: 0px;
    }

    .trainer-pitching-wrapper .heading {
        line-height: 40px;
    }

    .trainer-pitching-wrapper .pitch-btn-pink {
        margin-bottom: 10px;
    }

    .trainer-pitching-wrapper .bottom-content .description {
        line-height: 28px;
    }

    .trainer-pitching-wrapper .bottom-content .description {
        padding-bottom: 10px;
    }
}

@media(max-width: 767px) {
    .trainer-pitching-wrapper {
        padding: 50px 20px 22px;
    }

    .trainer-pitching-wrapper .profile-sec-wrap {
        min-width: 100%;
    }

    .trainer-pitching-wrapper .heading {
        line-height: 24px;
        font-size: 16px;
        max-width: 320px;
        margin: 0 auto;
        padding-bottom: 30px;
    }

    .trainer-pitching-wrapper .profile-img {
        width: 100px;
        height: 100px;
    }

    .trainer-pitching-wrapper .profile-section-trainer .caller-title {
        font-size: 14px;
        line-height: 22px;
    }

    .trainer-pitching-wrapper .profile-section-trainer .caller-name {
        font-size: 14px;
        line-height: 22px;
    }

    .trainer-pitching-wrapper .bottom-content .description {
        font-size: 12px;
        line-height: 20px;
        opacity: 50%;
        padding: 20px 0px;
    }

    .trainer-pitching-wrapper .bottom-content .bottom-line {
        font-size: 10px;
    }

    .trainer-pitching-wrapper .pitching-user {
        display: flex;
        background-color: rgba(255, 255, 255, 0.13);
        border-radius: 12px;
        margin-bottom: 6px;
        padding: 6px;
    }

    .trainer-pitching-wrapper .profile-section-trainer {
        max-width: unset;
        padding-bottom: 30px;
    }

    .trainer-pitching-wrapper .pitching-user img {
        width: 28px;
        height: 28px;
    }

    .trainer-pitching-wrapper .profile-sec-wrap .profile-image-wrap {
        display: none;
    }

    .trainer-pitching-wrapper .profile-sec-wrap .mob-title-pitch {
        display: block;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        padding: 18px 0px 7px;
    }

    .trainer-pitching-wrapper .profile-sec-wrap .profile-image-wrap .mid-title {
        text-align: left;
        font-size: 12px;
        padding-bottom: 7px;
    }

    .trainer-pitching-wrapper .profile-section-trainer .pitching-user-wrap {
        flex-direction: column;
    }

    .trainer-pitching-wrapper .profile-section-trainer .pitching-user-wrap .user-name {
        font-size: 12px;
        padding: 0px 0px 0px 14px;
    }

    .trainer-pitching-wrapper .bottom-content .prepare-button-mob {
        display: inline-block;
        width: 100%;
    }

    .trainer-pitching-wrapper .bottom-content .prepare-button {
        display: none;
    }
}