.employeesPageWrap .section1 {
    background: #150924;
    color: white;
}

.employeesPageWrap .section1 .heading {
    color: white;
    font-size: 56px;
    line-height: 68px;
    padding: 0px;
}

.employeesPageWrap .section1 .subheading {
    line-height: 34px;
}

.employeesPageWrap .primaryButton {
    background: #06efc5;
    font-size: 18px;
    font-weight: 800;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.employeesPageWrap .secondaryButton {
    background: none;
    color: #06efc5;
    font-size: 18px;
    font-weight: 800;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.employeesPageWrap .section2 {
    padding: 50px 100px;
    flex-wrap: wrap-reverse;
    justify-content: center;
    gap: 6vw;
}

.employeesPageWrap .section2 .section2Image {
    width: 507px;
}

.employeesPageWrap .section2 .tag {
    background: #ffb800;
    width: fit-content;
    font-size: 22px;
    font-weight: 600;
    color: black;
    border-radius: 100px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.employeesPageWrap .section2 .right-section {
    flex: 1;
}

.employeesPageWrap .section2 .right-section a:hover {
    text-decoration: none !important;
}

.employeesPageWrap .textBlack {
    color: #242424 !important;
    -webkit-text-fill-color: #242424;
}

.employeesPageWrap .section2 .right-section .heading {
    font-size: 46px;
    line-height: 58px;
    color: black;
    padding: 0;
}

.employeesPageWrap .section2 .right-section .description {
    font-size: 22px;
    color: #242424;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
}

.employeesPageWrap .section3 {
    background: #150924;
    color: white;
}

.employeesPageWrap .section3 .tag {
    font-size: 22px;
    font-weight: 600;
    line-height: 33px;
    color: #150924;
    background: #f495d3;
    width: fit-content;
    margin: auto;
    padding: 2px 15px;
    border-radius: 20px;
}

.employeesPageWrap .accordion-button:not(.collapsed) {
    color: #150924;
    background: #F495D3;
}

.employeesPageWrap .accordion-body {
    background: #2B1735;
}


.employeesPageWrap .section3 .subheading {
    margin-top: 10px;
    font-size: 39px;
    font-weight: 700;
    line-height: 75px;
    text-align: center;
    margin-bottom: 87px;
}


.employeesAccordian .accordion-button::after {
    display: none;
}

.employeesAccordian {
    display: flex;
    gap: 22px;
    flex-direction: column;
}

.employeesAccordian .accordion-button {
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;
    text-align: left;
}

.employeesAccordian .accordion-body {
    font-size: 20px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
}

.accordion-button:not(.collapsed) .iconImage {
    filter: brightness(0);
}

.accordion-button .iconImage {
    filter: brightness(100);
}

.employeesPageWrap .accordianWrap {
    max-width: 523px;
    width: 39%;
    flex: 1;
}

.employeesPageWrap .mobile {
    display: none;
}

@media screen and (max-width: 1024px) {
    .employeesPageWrap .section1 {
        gap: 50px;
    }
}


@media screen and (max-width: 768px) {
    .employeesPageWrap .section1 {
        gap: 50px;
    }

    .employeesPageWrap .ResponsiveWrapper {
        gap: 50px;
    }

    .employeesPageWrap .primaryButton {
        margin: 0 auto;
        width: 100%;
    }
}

@media screen and (max-width: 480px) {

    .employeesPageWrap .section1 .heading {
        font-size: 25px;
        line-height: normal;
    }

    .employeesPageWrap .section1 .heading br {
        display: none;
    }

    .employeesPageWrap .section1 .subheading {
        font-size: 15px !important;
        line-height: 19px;
    }

    .employeesPageWrap .primaryButton {
        font-size: 15px;
        line-height: normal;
    }

    .employeesPageWrap .secondaryButton {
        font-size: 15px;
    }

    .employeesPageWrap .section2 {
        padding: 50px 25px;
        gap: 0;
    }

    .employeesPageWrap .section2 .right-section .heading {
        font-size: 25px;
        line-height: 39px;
    }

    .employeesPageWrap .section2 .right-section .heading br {
        display: none;
    }

    .employeesPageWrap .section2 .tag {
        font-size: 12px;
    }

    .employeesPageWrap .section2 .tag img {
        width: 20px;
    }

    .employeesPageWrap .section2 .right-section .description {
        font-size: 15px;
        line-height: 19px;
        margin-bottom: 0 !important;
    }

    .employeesPageWrap .section2 .primaryButton {
        width: 100%;
        font-size: 15px;
    }

    .employeesPageWrap .section3 .tag {
        font-size: 12px;
    }

    .employeesPageWrap .section3 .subheading {
        font-size: 25px;
        line-height: 39px;
        margin-bottom: 37px;
    }

    .employeesPageWrap .accordianWrap {
        width: 100%;
        max-width: unset;
        min-width: unset;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .employeesAccordian .accordion-button {
        font-size: 20px;
        line-height: 25px;
    }

    .employeesAccordian .accordion-body {
        font-size: 12px;
        line-height: 19px;
    }

    .employeesPageWrap .desktop {
        display: none;
    }

    .employeesPageWrap .mobile {
        display: block;
    }

    .employeesPageWrap .section2 .image-wrap {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .employeesPageWrap .section2 .image-wrap a:hover {
        text-decoration: none !important;
    }
}