.subs .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0px;
}

.No-subs .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 90%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0px;
}

.sw .react-switch-bg {
  height: 22px !important;
  width: 50px !important;
}

.sw .react-switch-handle {
  height: 20px !important;
  width: 20px !important;
}

.subs .modal-fullscreen {
  height: auto !important;
  width: 100vw !important;
  max-width: none !important;
  margin: 0 !important;
}

.contact-sales {
  color: var(--Turquoise, #06EFC5);
  font-family: Urbanist;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.897px;
  padding: 10px;
}

.bg-img {
  background-image: url('../../../../public/assets/images/subs-bg.png');
  height: 100%;
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 60px;
}

.title {
  color: var(--White, #FFF);
  text-align: center;
  font-family: Urbanist;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
}

.cross {
  color: var(--White, #FFF);
  font-size: 20px;
  margin-left: auto;
  cursor: pointer;
}

/* .sub-row {
  align-items: center;
} */

.sub-row img {
  width: 95px;
}

.plan {
  color: var(--White, #FFF);
  text-align: center;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  margin-top: 10px;
}

.mon {
  color: #FFF;
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.897px;
}

.react-switch-bg {
  border: 1.4px white solid;
}

.sub-box {
  width: 270px;
  height: 100%;
  border-radius: 8.966px;
  border: 0.897px solid rgba(255, 255, 255, 0.10);
  background: rgba(255, 255, 255, 0.10);
  /* backdrop-filter: blur(2.689655303955078px); */
  padding: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.type-box {
  border-radius: 3.586px;
  border: 0.897px solid var(--White, #FFF);
  padding: 8px 12px;
}

.type-box-s {
  border-radius: 3.586px;
  border: 0.897px solid var(--Turquoise, #06EFC5);
  padding: 8px 12px;
  background-color: var(--Turquoise, #06EFC5);
}

.type-box div {
  color: var(--White, #FFF);
  text-align: center;
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.897px;
}

.type-box-s div {
  color: #000000;
  text-align: center;
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.897px;
}

.input-w {
  position: relative;

  label {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    opacity: 1;
    border: 1.5px solid rgb(255 255 255 / 40%);
    border-radius: 4px;
    cursor: pointer;

    &:after {
      content: "";
      background-size: auto;
      background-repeat: no-repeat;
      background-position: center;
      width: 18px;
      height: 18px;
      display: inherit;
      top: -0.5px;
      position: relative;
      left: -0.6px;
    }
  }

  input {
    visibility: hidden;

    &:checked {
      ~label {
        &:after {
          background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='14' height='14' rx='2' fill='%2306EFC5'/%3E%3Cpath d='M10.765 4.20474C11.0661 4.48915 11.0797 4.96383 10.7953 5.26497L6.54526 9.76497C6.40613 9.91228 6.21332 9.99703 6.01071 9.99993C5.8081 10.0028 5.61295 9.92361 5.46967 9.78033L3.21967 7.53033C2.92678 7.23744 2.92678 6.76257 3.21967 6.46967C3.51256 6.17678 3.98744 6.17678 4.28033 6.46967L5.98463 8.17397L9.70474 4.23503C9.98915 3.9339 10.4638 3.92033 10.765 4.20474Z' fill='%23242424'/%3E%3C/svg%3E");
        }
      }
    }
  }

  &.myradio {
    label {
      border-radius: 50%;

      &::after {
        border-radius: 50%;
        width: 15px;
        height: 15px;
        top: 1px;
        left: 1px;
        position: relative;
      }
    }

    input {
      visibility: hidden;

      &:checked {
        ~label {
          &:after {
            background: #0defc5;
          }
        }
      }
    }
  }
}

.price_nav {
  color: var(--Turquoise, #06EFC5);
  text-align: center;
  font-family: Urbanist;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.897px;
}

.price-strike_nav {
  color: var(--Gray-6, #F2F2F2);
  text-align: center;
  font-family: Urbanist;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.897px;
  text-decoration: line-through;
}

.time {
  color: rgba(255, 255, 255, 0.80);
  text-align: center;
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.897px;
}

.off {
  color: #06EFC5;
  text-align: center;
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.897px;
}

.center {
  align-items: center;
  display: flex;
  justify-items: flex-end;
  justify-content: end;
}

.hr {
  width: 90%;
  height: 3px;
  flex-shrink: 0;
  border-radius: 3.586px 3.586px 0px 0px;
  background: var(--Turquoise, #06EFC5);
  filter: blur(0px);
  margin-left: -20px;
}

.list-item {
  color: #FFF;
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.list ul li ::marker {
  display: none !important;
}

.check_nav_sub {
  width: 100%;
  height: 100%;
}

.list-row {
  align-items: center;
  display: flex;
  justify-items: flex-end;
}

.list-add {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 40px;
}

.button1 {
  width: 100%;
  height: 50px;
  flex-shrink: 0;
  border-radius: 8.966px;
  border: 0.897px solid #06EFC5;
  background: linear-gradient(89deg, #242424 -261.85%, rgba(36, 36, 36, 0.00) 98.43%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* .button1Active {
  width: 100%;
  height: 50px;
  flex-shrink: 0;
  border-radius: 8.966px;
  border: 0.897px solid #06EFC5;
  background: var(--Turquoise, #06EFC5); 
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
} */
.button1Active {
  width: 100%;
  height: 50px;
  flex-shrink: 0;
  border: 0.9px solid #06EFC5;
  border-radius: 8.97px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  font-weight: 500;
  background-color: transparent;
}
.button1Active:hover{
  background: var(--Turquoise, #06EFC5);
  color: #000000;
}

.btn-txt {
  color: var(--ds-neural-03, #FFF);
  text-align: center;
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.14px;
}


.btn-continue {
  border-radius: 10px;
  background: #06EFC5;
  /* filter: blur(2px); */
  display: flex;
  width: 40%;
  height: 56px;
  padding: 13px 49px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  box-shadow: 0px 2px 24px 0px rgba(16, 155, 255, 0.24);
  cursor: pointer;
}

.cont {
  color: #100027;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.165px;
}

.cards-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.justify-cent {
  justify-content: center;
}

.noSub {
  width: 500px;
  font-size: 16px;
  color: #FFFFFF;
  font-weight: 500;
}

@media screen and (min-width:750px) and (max-width:900px) {
  .cards-row {
    justify-content: unset;
    overflow: auto;
  }
}

@media screen and (min-width:350px) and (max-width:740px) {
  .cards-row {
    justify-content: unset;
    overflow: auto;
  }

  .bg-img {
    padding: 20px;
  }

  .justify-cent {
    justify-content: unset;
    align-items: center;
  }

  .mon {
    font-size: 15px;
  }

  .column-three {
    order: 3;
    margin-top: 10px;
  }

  .title {
    font-size: 26px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 540px) {
  .main-div{
    display: block !important;
  }
  .col-md-5.col-6.p-0 {
      flex: 0 0 41.666667%;
      max-width: 100%;
  }
  .subs-year-text{
    margin-top: 0px !important;
    width: 140px !important;
    margin: 0px auto !important;
    padding-bottom: 20px !important;
  }
  .type-box{
    width: 100px !important;
  }
  .list-add{
    /* flex-direction: row; */
    margin-left: 5px;
    /* margin-top: 20px; */
  }
  .one{
    top: 62% !important;
    left: 24%;
    margin-left: -26px !important;
  }
  /* .navSubscription{
   width: 100% !important;
  } */
  .list-item{
    font-size: 13px;
  }
  .margn-top38{
    padding: 0px !important;
  }
}
.price-wise {
  color: rgba(255, 255, 255, 0.80);
  text-align: center;
  font-family: Urbanist;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.0625rem;
}

.navSubscription {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
  border-radius: 8.966px;
  border: 0.897px solid rgba(255, 255, 255, 0.10);
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(2.689655303955078px);
}
.selectedSubscription {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
  border-radius: 8.97px;
  border: 0.9px solid #06EFC5;
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(2.689655303955078px);
}
.promoCode{
  width: fit-content;
  margin: 10px auto;
}
.promo-code{
  background: transparent;
  border-color: #FFFFFF;
  border-radius: 5px; 
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(2.689655303955078px);
  font-size: 16px;
  padding: 7px 30px;
  color: #FFFFFF;
}
.promo-code::placeholder{
  color: #9A9A9A;
}
input[type=radio]#radio { 
  width: 15px;
  height: 15px;
  accent-color: #06EFC5; 
} 
.heading{
  color: #06EFC5;
  font-size: 16px;
  width: fit-content;
  padding: 10px 0px 0px 10px;
}
.price-details{
  display: flex;
  justify-content: space-between;
  padding: 7px 0px;
}
.price-details-text{
  color: #FFF;
  font-size: 12px;
  font-weight: 300;
}
.btn_extra1 {
  color: var(--Turquoise, #06EFC5);
  text-align: center;
  font-family: Urbanist;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.165px;
}

.btn_extra2 {
  color: var(--Turquoise, #06EFC5);
  text-align: right;
  font-family: Urbanist;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.165px;
}

.navSubsCardTitle {
  margin-top: 1rem;
  color: var(--White, #FFF);
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(2.689655303955078px);
  margin-left: 10px;
}

.dotActive {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--Turquoise, #06EFC5);
  backdrop-filter: blur(2.689655303955078px);
  margin-left: 10px;
}

.divPointer {
  cursor: pointer;
}

.hrcard {
  width: 100%;
  height: 3px;
  flex-shrink: 0;
  border-radius: 3.586px 3.586px 0px 0px;
  background: var(--Turquoise, #06EFC5);
  filter: blur(0px);
  /* margin-left: -20px; */
}

/* .button1Active {
  width: 100%;
  height: 50px;
  flex-shrink: 0;
  border-radius: 8.966px;
  border: 0.897px solid #06EFC5;
  background: var(--Turquoise, #06EFC5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

} */

.btn-txtActive {
  color: black;
  text-align: center;
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.14px;
}
.cancel-sub-box{
  background-color: #30273E;
  border-radius:12px;
}
.cancel-heading{
    color: var(--White, #FFF);
    text-align: center;
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1px;
}
.cancel-sub-subHeading{
  color: var(--White, #FFF);
  text-align: center;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
  opacity: 0.8;
}
.cancel-sub-terms{
  color: var(--White, #FFF);
  text-align: center;
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
  opacity: 0.8;
}
.cancel-sub-terms span{
  text-decoration-line: underline;
}
.cancel-sub-btn-cancel{
  cursor:pointer;
  opacity: 0.44;
}
.cancel-sub-btn-confirm{
  cursor:pointer;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius:12px;
}
.cancel-sub-btn span{
  color: var(--White, #FFF);
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.165px;
}
.cancel-sub-btn span.confirm{
  color: #EB5757;

}
