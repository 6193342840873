.mission-wrapper {
    background-color: #ffff;
}

.mission-content {
    padding: 75px 0px 75px 100px;
}

.mission-content .description {
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 33px;
    padding-bottom: 30px;

}

.mission-content .mission-list {
    padding-left: 20px;
}

.mission-content .mission-list ul li {
    padding-bottom: 10px;
    font-size: 20px;
}

.mission-content .mission-list ul span {
    font-size: 20px;
}

.mission-content .mission-list ul li::marker {
    color: #06EFC5;
}

.mission-img-desktop {
    display: block;
}

.mission-img-mob {
    display: none;
}

@media (max-width: 768px) {
    .mission-content {
        padding: 47px 26px;
    }

    .mission-content .description {
        font-size: 20px;
        line-height: 25px;
    }

    .mission-content .mission-list ul li {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        padding-bottom: 20px;
    }

    .mission-img-desktop {
        display: none;
    }

    .mission-img-mob {
        display: block;
    }

    .mission-img-mob img {
        width: 100%;
    }
}