.section1 {
    background: #150924;
    color: white;
    padding: 51px 100px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.section1 .heading-wrap {
    padding-top: 40px;
    flex: 1;
    max-width: 60%; /* Increased from 50% to 60% */
}

.section1 .subheading {
    line-height: 34px;
}

.section1 .mobile-image {
    display: none;
}

.section1 .buttons-group {
    padding-top: 30px;
}

.section1 .buttons-group a {
    text-decoration: none !important;
    text-align: center;
    color: #141414 !important;
}

.section1 .buttons-group a:hover {
    text-decoration: none !important;
}

.section1 .image-container {
    width: 40%; /* Decreased from 50% to 40% */
    min-width: 250px; /* Adjusted min-width */
    max-width: 500px; /* Adjusted max-width */
}

@media screen and (max-width: 768px) {
    .section1 {
        padding: 30px 25px;
        flex-direction: column;
    }

    .section1 .heading-wrap {
        max-width: 100%;
    }

    .section1 .mobile-image {
        display: block;
        padding: 50px 0;
        position: relative;
    }

    .section1 .image-container {
        display: none;
    }

    .section1 .buttons-group {
        padding-top: 0px;
    }

    .heading-wrap span {
        padding: 0px 4px !important;
        display: inline-block;
    }
}