.verified_main_container{
    min-height:100vh;
    background: linear-gradient(82deg, rgba(255, 255, 255, 0.20) -146.09%, rgba(255, 255, 255, 0.00) 58.97%), #100027;
    background-image: url("../../../../public/assets/images/ellipse342.png");
    background-repeat: no-repeat;
    background-position: top center;
}



.data-container{
    height:100%;
    box-sizing: border-box;
}

.verify_custom_btn{
    padding:5px;
}
.verify_btn_txt{
    color: var(--Turquoise, #06EFC5);
    text-align: center;
    font-family: Open Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.01031rem;
}
.verify_step_txt{
    color: var(--White, #FFF);
    text-align: center;
    font-family: Urbanist;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.0625rem;
    opacity: 0.4;
}

.v_cong_container{
    width:75%;
}

.v_acc_btn{
    padding: 0.625rem 1.25rem;
    margin:auto auto;
    border-radius: 4.9375rem;
    border: 0.5px solid #FFF;
    background: rgba(6, 239, 197, 0.70);
    box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.20);
}
.v_acc_btn_txt{
    color: var(--White, #FFF);
    padding:0px;
    text-align: center;
    font-family: Urbanist;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.0625rem;
}

.v_cong_txt{
    color: #11D4B1;
    text-align: center;
    font-family: Urbanist;
    font-size: 2.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.0625rem;
    text-transform: uppercase;
    
}
.v_subs_txt{
    color: var(--White, #FFF);
    text-align: center;
    font-family: Urbanist;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 1.7875rem */
    letter-spacing: 0.0625rem;
}

.v_plan_btn{
    width:16.9375rem;
    margin:auto auto;
    display: inline-flex;
    padding: 1rem 2.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.625rem;
    background: var(--Turquoise, #06EFC5);
}
.v_plan_txt{
    color: #100027;
    text-align: center;
    font-family: Open Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.01031rem;
}

.logo-position{
    position: absolute;
    top:3rem;
    left:5rem;
}
@media screen and (max-width:1200px) {
    .logo-position{
        top:3rem;
        left:2rem;
    }
    
}
@media screen and (max-width:768px) {
    .logo-position{
        display: none;
    }
    
}
@media screen and (max-width:476px) {
    .design-img-container img{
        width:80%;
    }
    .v_cong_txt{
        font-size: 2rem;
    }
    
}


.v_margin_top{
    margin-top:8rem;
    margin-bottom:4rem;
}

.mr-tp{
    margin-top:6.25rem;
}
