.control-panel {
  position: absolute;
  top: 20px;
  left: 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.20);
  box-shadow: 0px 2px 6px 0px rgba(5, 27, 96, 0.20);
  backdrop-filter: blur(7.5px);
  width: 310px;
  height: 532px;
  overflow-y: auto;
  /* display: grid; */
}

.center-img {
  width: 104px;
  height: 115px;
  margin: auto;
  margin-top: 80%;
}

.text {
  color: white;
  text-align: center;
  font-family: 'Urbanist';
  font-size: 16px;
}

.content {
  align-items: center;
  display: grid;
  width: 60%;
  margin: auto;
}

.ep-row {
  padding: 8px;
  display: flex;
  border-bottom: 1.5px solid rgba(255, 255, 255, 0.20);
  width: 100%;
  height: 80px;
  align-items: center;
  cursor: pointer;
}

.dp {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.name {
  color: var(--White, #FFF);
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.14px;
}

.role {
  color: var(--White, #FFF);
  font-family: Urbanist;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.1px;
  opacity: 0.6;
}

.dist-box {
  width: auto;
  height: 50px;
  border-radius: 10px;
  background: rgba(6, 239, 197, 0.24);
  padding: 5px;
}

.dist {
  color: #06EFC5;
  text-align: center;
  font-family: Urbanist;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
}

.kms {
  color: white;
  text-align: center;
  font-family: Urbanist;
  font-size: 8px;
}

.mob-card {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.20);
  box-shadow: 0px 2px 6px 0px rgba(5, 27, 96, 0.20);
  backdrop-filter: blur(7.5px);
  /* width: 250px; */
  height: 100%;
  margin-right: 10px;
}

.horizontal-slider {
  width: 100%;
  max-width: 500px;
  height: 50px;
  /* border: 1px solid grey; */
}

.example-thumb {
  font-size: 0.9em;
  text-align: center;
  background-color: white;
  cursor: pointer;
  border-radius: 50px;
  height: 18px;
  width: 18px;
  top: -6px
}

.example-track {
  position: relative;
  background: #38BBF4;
  height: 2px;
  border-radius: 10px;
}

.example-track.example-track-1 {
  background: white;
}

.horizontal-slider {
  height: 15px;
}

.cancel-btn {
  color: var(--Red, #EB5757);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.16px;
  margin-top: 8%;
}

.job-icon-bg {
  background-image: url('../../../../public//assets//images/job-bg.png');
  height: 70px;
  width: 70px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  place-items: center;
  place-content: center;
}

.avatar-marker {
  height: 30px;
  width: 30px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  place-items: center;
  place-content: center;
  border-radius: 50px;
}

@media only screen and (min-width:501px) {
  .control-panel-mobile {
    display: none;
  }
}

@media only screen and (max-width:500px) {
  .dp {
    width: 40px;
    height: 40px;
  }

  .dist-box {
    width: auto;
    height: 40px;
    padding: 2px;
  }

  .dist {
    font-size: 15px;
  }

  .kms {
    font-size: 7px;
    margin-top: -2px;
  }

  .ep-row {
    height: auto;
    border: none;
  }

  .control-panel-mobile {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 100%;
    overflow-x: auto !important;
    flex-wrap: nowrap !important;
    display: flex !important;
    height: auto;
    scrollbar-width: none !important;
  }
}