.results-page-wrap {
    min-height: 100vh;
    font-family: "Inter", sans-serif;
}

.results-page-wrap .results-content-wrap {
    padding: 20px 50px;
    background: #150924;
    min-height: calc(100vh - 83px);
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}

.results-page-wrap .result-mobile-header {
    display: none;
}

.results-page-wrap .results-content-header {
    display: flex;
    justify-content: space-between;
}

.results-page-wrap .back {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #ffffff;
    display: flex;
    gap: 12px;
}

.results-page-wrap .improvment-title {
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.02em;
    text-align: left;
    color: #ffb800;
    display: flex;
    gap: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.results-page-wrap .greenColor {
    color: #06efc5;
}

.results-page-wrap .based-on-title {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #ffffff;
}

.results-page-wrap .buttons-wrap {
    display: flex;
    margin: 30px 0px;
    gap: 30px;
}

.results-page-wrap .video-container {
    position: relative;
    width: 43%;
    max-width: 480px;
    /* height: auto; */
    cursor: pointer;
    height: 450px;
}

.results-page-wrap video {
    width: 100%;
    height: 100%;
}

.play-button-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 48px;
    color: white;
    text-shadow: 0px 0px 5px black;
    pointer-events: none;
}

.results-page-wrap .primaryButton {
    background: #06efc5;
    font-size: 18px;
    font-weight: 800;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    text-wrap: nowrap;
    line-height: normal;
    padding: 13px 17px;
    min-width: 357px;
    height: fit-content;
    -webkit-text-fill-color: #242424;
}

.results-page-wrap .secondaryButton {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    background: #ffffff1a;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    font-family: "Inter", sans-serif;
    text-wrap: nowrap;
    height: fit-content;
    padding: 13px 25px;
    line-height: normal;
    -webkit-text-fill-color: #ffffff;
}

.results-page-wrap .downloadButton {
    font-size: 16px;
    padding: 15px 16px;
}

.results-page-wrap .feedbacks-content-wrap {
    background: #291e36;
    color: white;
    padding: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 68vw;
}

.results-page-wrap .feedbacks-content-wrap .feedback-heading {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 20px;
}

.results-page-wrap .feedbacks-content-wrap .feedback-content-wrap {
    max-height: 44vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-right: 20px;
}

.results-page-wrap .feedbacks-content-wrap .feedback-card-wrap {
    display: flex;
    gap: 16px;
}

.results-page-wrap .feedbacks-content-wrap .feedback-card-wrap .ques-title {
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.02em;
    text-align: left;
}

.results-page-wrap .feedbacks-content-wrap .feedback-card-wrap .ques-desc {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.02em;
    text-align: left;
}

.results-page-wrap .feedbacks-content-wrap .feedback-card-wrap .ans-title {
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 12px;
}

.results-page-wrap .feedbacks-content-wrap .feedback-card-wrap .ans-desc {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.02em;
    text-align: left;
}

.results-page-wrap .feedbacks-content-wrap .feedback-card-wrap .ans-wrap {
    margin-top: 20px;
    padding-left: 10px;
}

.results-page-wrap .feedbacks-content-wrap .feedback-card-wrap .ques-wrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 42px;
    justify-content: center;
}

.results-page-wrap .feedbacks-content-wrap .feedback-card-wrap .ans-desc p:nth-of-type(1) {
    margin-bottom: 8px;
}

.results-page-wrap .feedbacks-content-wrap .feedback-card-wrap .ans-desc .lineheightPara {
    line-height: 32px;
    margin-bottom: 0;
}

.results-page-wrap .feedback-footer-wrap {
    display: flex;
    gap: 23px;
    justify-content: space-between;
}

.results-page-wrap .mobile-feedback-footer-wrap {
    display: none;
}

.results-page-wrap .results-pagination-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 20px;
    width: fit-content;
    color: white;
    gap: 100px;
}

.results-page-wrap .mobile-results-pagination-wrap {
    display: none;
}

.results-page-wrap .mobile-buttons-wrap {
    display: none;
}

@media screen and (max-width: 1650px) {
    .results-page-wrap .improvment-title {
        margin: 10px 0px;
    }

    .results-page-wrap .buttons-wrap {
        margin: 10px 0px;
    }

    .results-page-wrap .primaryButton {
        padding: 8px 15px;
    }

    .results-page-wrap .secondaryButton {
        padding: 8px 15px;
    }

    .results-page-wrap .feedbacks-content-wrap .feedback-card-wrap .ans-desc .lineheightPara {
        line-height: 20px;
    }

    .results-page-wrap .feedbacks-content-wrap .feedback-card-wrap .ans-wrap {
        margin-top: 8px;
    }

    .results-page-wrap .video-container {
        width: 25%;
        height: 350px;
    }
}

@media screen and (max-width: 768px) {
    .results-page-wrap .feedback-footer-wrap {
        flex-wrap: wrap-reverse;
    }

    .results-page-wrap .buttons-wrap {
        flex-wrap: wrap;
    }

    .results-page-wrap .video-container {
        width: 100%;
        max-width: unset;
    }

    .results-page-wrap .feedbacks-content-wrap {
        width: 100%;
    }

    .results-page-wrap .primaryButton {
        width: 100%;
        min-width: unset;
    }

    .results-page-wrap .secondaryButton {
        width: 100%;
    }

    .results-page-wrap .results-content-header {
        flex-wrap: wrap;
    }

    .results-page-wrap .feedbacks-content-wrap .feedback-content-wrap {
        max-height: unset;
    }
}

@media screen and (max-width: 480px) {
    .results-page-wrap .results-content-wrap {
        padding: 10px 20px;
    }

    .results-page-wrap .results-content-header {
        display: none;
    }

    .results-page-wrap .result-mobile-header {
        display: flex;
        flex-direction: column;
    }

    .results-page-wrap .roundButton {
        width: auto;
        border-radius: 100px;
        padding: 8px !important;
    }

    .results-page-wrap .back {
        font-size: 14px;
    }

    .results-page-wrap .improvment-title {
        background: #ffb800;
        color: black;
        gap: 9px;
        font-size: 12px;
        border-radius: 10px;
        padding: 10px 14px;
        margin-top: 15px;
        margin-bottom: 17px;
    }

    .results-page-wrap .based-on-title {
        font-size: 12px;
    }

    .results-page-wrap .buttons-wrap {
        display: none;
    }

    .results-page-wrap .feedback-footer-wrap {
        display: none;
    }

    .results-page-wrap .mobile-feedback-footer-wrap {
        display: flex;
        margin-top: 12px;
    }

    .results-page-wrap .feedbacks-content-wrap {
        padding: 16px 12px;
    }

    .results-page-wrap .feedbacks-content-wrap .feedback-heading {
        font-size: 12px;
    }

    .results-page-wrap .feedbacks-content-wrap .feedback-card-wrap .ques-title {
        font-size: 12px;
    }

    .results-page-wrap .feedbacks-content-wrap .feedback-card-wrap .ques-desc {
        font-size: 12px;
        margin-bottom: 5px;
    }

    .results-page-wrap .feedbacks-content-wrap .feedback-card-wrap .ans-title {
        font-size: 12px;
        margin-bottom: 3px;
    }

    .results-page-wrap .feedbacks-content-wrap .feedback-card-wrap .ans-desc {
        font-size: 12px;
    }

    .results-page-wrap .feedbacks-content-wrap .feedback-card-wrap .ans-desc .lineheightPara {
        line-height: 18px;
    }

    .results-page-wrap .feedback-content-wrap .feedback-card-wrap .feedback-icons {
        width: 18px;
    }

    .results-page-wrap .feedbacks-content-wrap .feedback-content-wrap {
        gap: 15px;
    }

    .results-page-wrap .feedbacks-content-wrap .feedback-card-wrap .ques-wrap {
        min-height: 26px;
    }

    .results-page-wrap .feedbacks-content-wrap .feedback-card-wrap .ans-wrap {
        margin-top: 5px;
    }

    .results-page-wrap .feedbacks-content-wrap .feedback-card-wrap .ans-desc p:nth-of-type(1) {
        margin-bottom: 1px;
        line-height: 18px;
    }

    .results-page-wrap .mobile-video-wrap {
        display: flex;
        background: #382e4b;
        border-radius: 10px;
        overflow: hidden;
        padding: 8px;
        gap: 11px;
        margin-left: 31px;
        margin-top: 20px;
    }

    .results-page-wrap .mobile-video-wrap .video-heading {
        font-size: 12px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.02em;
        text-align: left;
    }

    .results-page-wrap .mobile-video-wrap .video-desc {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.02em;
        text-align: left;
    }

    .results-page-wrap .mobile-video-wrap .video-desc span {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.02em;
        text-align: left;
        text-decoration: underline;
        white-space: nowrap;
    }

    .results-page-wrap .play-button-overlay img {
        width: 16px;
    }

    .results-page-wrap .video-container {
        width: 80px;
        border-radius: 10px;
        overflow: hidden;
        height: unset;
    }

    .results-page-wrap .results-pagination-wrap {
        display: none;
    }

    .results-page-wrap .mobile-results-pagination-wrap {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: -0.02em;
        text-align: center;
        margin-top: 25px;
    }

    .results-page-wrap .mobile-results-pagination-wrap img {
        width: 16px;
    }

    .results-page-wrap .primaryButton {
        font-size: 12px;
        padding: 17px 5px;
        height: fit-content;
    }

    .results-page-wrap .greenColor {
        background-color: #06efc5;
    }

    .results-page-wrap .primaryButton img {
        width: 16px;
    }

    .results-page-wrap .secondaryButton {
        font-size: 12px;
        padding: 17px 5px;
        height: fit-content;
    }

    .results-page-wrap .secondaryButton img {
        width: 16px;
    }

    .results-page-wrap .mobile-buttons-wrap {
        display: flex;
        gap: 12px;
        margin-top: 20px;
    }
}