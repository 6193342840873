.hr-avatar-page-wrap .drop-file-wrap {
    width: 100%;
}

.hr-avatar-page-wrap .desktop-footer-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 27px;
    gap: 17px;
}

.hr-avatar-page-wrap .chat-button-wrap {
    background: white;
    height: max-content;
    border-radius: 100px;
    padding: 20px;
    position: relative;
}

.hr-avatar-page-wrap .chat-button-wrap .message-count {
    position: absolute;
    top: -8px;
    background: #ffb800;
    right: -14px;
    padding: 6px 13px;
    border-radius: 100px;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #000000;
}

.hr-avatar-page-wrap .dropFileText {
    font-size: 22px;
    font-weight: 700;
    line-height: 75px;
    letter-spacing: -0.02em;
    text-align: center;
}

.hr-avatar-page-wrap .drop-file-title {
    width: 100%;
    margin: auto;
    font-size: 22px;
    font-weight: 700;
    line-height: 75px;
    letter-spacing: -0.02em;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
}

.hr-avatar-page-wrap .drop-file-title img {
    width: 44px;
    height: 44px;
}

.hr-avatar-page-wrap .left-content-resize {
    height: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}

.hr-avatar-page-wrap .left-content-resize .caller-img {
    width: 100%;
    height: 100%;
}

.hr-avatar-page-wrap .left-content-resize .bottom-title {
    font-size: 16px !important;
}

.hr-avatar-page-wrap .right-content-resize .bottom-title {
    padding: 17px 20px;
    /* height: unset !important; */
    font-size: 16px !important;
}

.hr-avatar-page-wrap .right-content-resize {
    height: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 40vw;
}

.hr-avatar-page-wrap .icon-button {
    background-color: transparent;
    border: none;
}

.hr-avatar-page-wrap .chat-container {
    background-color: white;
    height: 40vw;
    margin-left: 16px;
}

@media (min-width: 1450px) {
    .hr-avatar-page-wrap .trainer-mode-wrap .left-img-wrap .bottom-icon-wrap {
        height: 70px !important;
    }

    .hr-avatar-page-wrap .trainer-mode-wrap .right-img-wrap .bottom-title {
        height: 70px !important;
    }

    .hr-avatar-page-wrap .desktop-footer-wrap {
        margin-top: 15px;
    }

    .hr-avatar-page-wrap .trainer-mode-bottom-content .left-img-wrap .icon-img {
        width: 30px;
        height: 30px;
    }

    .hr-avatar-page-wrap .chat-button-wrap {
        padding: 10px;
    }

    .hr-avatar-page-wrap .trainer-mode-bottom-content .left-img-wrap .caller-img {
        width: 350px;
    }

    .hr-avatar-page-wrap .trainer-mode-bottom-content .right-img-wrap .caller-img {
        width: 350px;
    }

    .hr-avatar-page-wrap .dropFileText {
        line-height: 45px;
        font-size: 18px;
    }

    .hr-avatar-page-wrap .left-content-resize .caller-img {
        width: 400px;
        height: 400px;
    }

    .hr-avatar-page-wrap .left-content-resize {
        height: 24vw;
    }

    .hr-avatar-page-wrap .right-content-resize {
        height: 24vw;
    }

    .hr-avatar-page-wrap .chat-container {
        height: 24vw;
    }

    .hr-avatar-page-wrap .right-content-resize .caller-img {
        width: 400px;
        height: 400px;
    }

    .hr-avatar-page-wrap .trainer-mode-bottom-content .left-img-wrap .bottom-icon-wrap {
        height: 40px;
    }

    .hr-avatar-page-wrap .trainer-mode-bottom-content .right-img-wrap .bottom-title {
        height: 40px;
    }
}

@media screen and (max-width: 768px) {
    .hr-avatar-page-wrap .desktop-chat {
        display: none;
    }

    .hr-avatar-page-wrap .desktop-footer-wrap {
        display: none;
    }

    .mobile-chat-wrap .MuiPaper-root {
        height: calc(90% - 56px) !important;
    }

    .chat-mob-screen .chat-container {
        border: none;
        box-shadow: unset;
    }

    .chat-mob-screen .chat-container .chat-header {
        display: block;
    }

    .chat-mob-screen .chat-container .chat-header .close-icon {
        display: none;
    }

    .chat-mob-screen .chat-container .chat-header .title {
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 27px;
    }

    .chat-mob-screen .chat-container .chat-input {
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 13px 20px 45px;
    }

    .mobile-chat-wrap .drawer-content-heading {
        top: -25px !important;
    }

    .mobile-chat-wrap .drawer-content-wrap {
        margin-top: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.language-selector {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    gap: 8px;
    z-index: 10;
  }
  
  .language-btn {
    padding: 8px 16px;
    border-radius: 20px;
    border: 1px solid rgba(255,255,255,0.2);
    background: rgba(0,0,0,0.5);
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .language-btn.active {
    background: #06EFC5;
    color: #241534;
    border-color: #06EFC5;
  }
  
  .caller-img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 8px;
    background-color: #000;
  }
  
  .caller-img.animated {
    animation: fadeIn 0.5s ease-in-out;
  }
  
  @keyframes fadeIn {
    from { opacity: 0.5; }
    to { opacity: 1; }
  }

  .spinner {
    width: 20px;
    height: 20px;
    border: 2px solid #ffffff;
    border-top: 2px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    display: inline-block;
    margin-right: 8px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .green-trainer-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .green-trainer-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }