.employersPageWrap .section1 {
    background: #150924;
    color: white;
}

.employersPageWrap .section1 .heading {
    color: white;
    font-size: 56px;
    line-height: 68px;
    padding: 0px;
}

.employersPageWrap .section1 .subheading {
    line-height: 34px;
}

.employersPageWrap .sec-2-btn {
    margin-bottom: 30px;
}

.employersPageWrap .sec-2-btn a {
    text-decoration: none;
    color: #141414 !important;
}
.employersPageWrap .textBlack {
    color: #242424 !important;
    -webkit-text-fill-color: #242424;
}

.employersPageWrap .sec-2-btn a:hover {
    text-decoration: none !important;
}

.Job-search-section .wrapper a {
    color: #141414 !important;
    text-decoration: none !important;
}

.Job-search-section .wrapper a:hover {
    text-decoration: none !important;
}

.employersPageWrap .primaryButton {
    background: #06efc5;
    font-size: 18px;
    font-weight: 800;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.employersPageWrap .secondaryButton {
    background: none;
    color: #06efc5;
    font-size: 18px;
    font-weight: 800;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.employersPageWrap .section2 {
    background-image: url("../../../public/assets/images/employersSection2.png");
    min-height: 353px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    background-color: #150924;
}

.employersPageWrap .section2 .heading {
    font-size: 34px;
    color: white;
    font-weight: 700;
    line-height: 47px;
    text-align: center;
    width: unset;
    padding: unset;
}

.employersPageWrap .section3 {
    background: #150924;
    color: white;
}

.employersPageWrap .section3 .tag {
    font-size: 22px;
    font-weight: 600;
    line-height: 33px;
    color: #150924;
    background: #38bbf4;
    width: fit-content;
    margin: auto;
    padding: 2px 15px;
    border-radius: 20px;
}

.employersPageWrap .accordion-button:not(.collapsed) {
    color: #150924;
    background: #38bbf4;
}

.employersPageWrap .accordion-body {
    background: #191b38;
}

.employersPageWrap .section3 .subheading {
    margin-top: 10px;
    font-size: 39px;
    font-weight: 700;
    line-height: 75px;
    text-align: center;
    margin-bottom: 87px;
}

.employersPageWrap .section4 {
    background: #150924;
    color: white;
    padding: 100px 0;
}

.employersPageWrap .section4 .heading {
    font-size: 39px;
    font-weight: 700;
    line-height: 75px;
    text-align: center;
    color: white;
    width: unset;
    padding: unset;
    margin-bottom: 65px;
}

.employersPageWrap .section4 .slider-container {
    width: 100%;
    overflow: hidden;
}

.employersPageWrap .section4 .card-wrap {
    height: 280px;
    padding: 40px 26px;
    margin-right: 40px;
    overflow: hidden;
}

.employersPageWrap .section4 .card-wrap .title {
    font-size: 54px;
    font-weight: 800;
    line-height: 33px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 25px;
    font-family: "Inter", sans-serif;
}

.employersPageWrap .section4 .card-wrap .description {
    font-size: 22px;
    font-weight: 300;
    line-height: 33px;
    letter-spacing: -0.02em;
    text-align: left;
}

.employersPageWrap .employeesAccordian .accordion-button::after {
    display: none;
}

.employersPageWrap .employeesAccordian {
    display: flex;
    gap: 22px;
    flex-direction: column;
}

.employersPageWrap .employeesAccordian .accordion-button {
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;
    text-align: left;
}

.employersPageWrap .employeesAccordian .accordion-body {
    font-size: 20px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
}

.employersPageWrap .accordion-button:not(.collapsed) .iconImage {
    filter: brightness(0);
}

.employersPageWrap .accordion-button .iconImage {
    filter: brightness(100);
}

.employersPageWrap .accordianWrap {
    max-width: 523px;
    width: 39%;
    flex: 1;
}

@media screen and (max-width: 1420px) {
    .employersPageWrap .section4 .card-wrap .description {
        font-size: 20px;
    }
}

@media screen and (max-width: 1024px) {
    .employersPageWrap .section1 {
        gap: 50px;
    }
}

@media screen and (max-width: 768px) {
    .employersPageWrap .section1 {
        gap: 50px;
    }

    .employersPageWrap .ResponsiveWrapper {
        gap: 50px;
    }

    .employersPageWrap .section4 .slider-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .employersPageWrap .section4 .card-wrap {
        height: unset;
        width: 100%;
        padding: 34px 26px;
    }
    .employersPageWrap .primaryButton {
        font-size: 15px;
        width: 100%;
        line-height: normal;
    }
}

@media screen and (max-width: 480px) {
    .employersPageWrap .section1 .heading {
        font-size: 25px;
        line-height: normal;
    }

    .employersPageWrap .section1 .heading span {
        display: inline-block;
    }

    .employersPageWrap .section1 .heading br {
        display: none;
    }

    .employersPageWrap .section1 .subheading {
        font-size: 15px !important;
        line-height: 19px;
    }

    .employersPageWrap .primaryButton {
        font-size: 15px;
        width: 100%;
        line-height: normal;
    }

    .employersPageWrap .secondaryButton {
        font-size: 15px;
        margin: 0 auto;
    }

    .employersPageWrap .section2 {
        padding: 25px;
    }

    .employersPageWrap .section2 .heading {
        font-size: 25px;
        line-height: 39px;
        padding-top: 20px;
    }

    .employersPageWrap .section3 .tag {
        font-size: 12px;
    }

    .employersPageWrap .section3 .subheading {
        font-size: 25px;
        line-height: 39px;
        margin-bottom: 37px;
    }

    .employersPageWrap .accordianWrap {
        width: 100%;
        max-width: unset;
        min-width: unset;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .employersPageWrap .employeesAccordian .accordion-button {
        font-size: 20px;
        line-height: 25px;
    }

    .employersPageWrap .employeesAccordian .accordion-body {
        font-size: 12px;
        line-height: 19px;
    }

    .employersPageWrap .section4 .heading {
        font-size: 25px;
        line-height: 39px;
    }

    .employersPageWrap .section4 {
        padding: 50px 25px;
    }

    .employersPageWrap .section4 .slider-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .employersPageWrap .section4 .card-wrap {
        height: unset;
        width: 100%;
        padding: 34px 26px;
    }

    .employersPageWrap .section4 .card-wrap .title {
        font-size: 25px;
        line-height: normal;
        margin-bottom: 20px;
    }

    .employersPageWrap .section4 .card-wrap .description {
        font-size: 12px;
        line-height: 19px;
        margin-bottom: 0;
    }
}