.hr-interview {
    min-height: 100vh;
    background-color: #0F0125;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 50px;
}

.hr-interview .profile-sec-wrap {
    justify-content: space-between;
    min-width: 750px;
    margin: 0 auto;
    position: relative;
}

.hr-interview .profile-sec-wrap .arrow-img-wrap {
    text-align: center;
    position: absolute;
    top: 15%;
    display: block;
    right: -65%;
}

.hr-interview .logo-img {
    max-width: 150px;
    /* margin-bottom: 20px; */
}

.hr-interview .heading {
    font-size: 26px;
    font-weight: 700;
    line-height: 75px;
    color: white;
    text-align: center;
    margin-bottom: 10px;
    width: unset;
    font-family: "Inter", sans-serif;
}

.hr-interview .user-name {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #ffff;
    font-family: "Inter", sans-serif;
}

.hr-interview .user-name span {
    font-weight: 700;
}

.hr-interview .icon-title {
    font-size: 12px;
    font-weight: 400;
    line-height: 35px;
    font-family: "Inter", sans-serif;
}

.hr-interview .profile-section {
    margin-bottom: 40px;
    position: relative;
}

.hr-interview .profile-section .caller-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    font-family: "Inter", sans-serif;
}

.hr-interview .profile-section .caller-name {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    font-family: "Inter", sans-serif;
    text-wrap: nowrap;
}

.hr-interview .profile-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.hr-interview .icons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 10px;
}

.hr-interview .icon-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.hr-interview .icon-img {
    width: 40px;
    height: 40px;
}

.hr-interview .bottom-content {
    justify-content: center;
    overflow: hidden;
}

.hr-interview .bottom-content .description {
    max-width: 582px;
    margin: 0 auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 35px;
    padding-bottom: 26px;
    font-family: "Inter", sans-serif;
}

.hr-interview .bottom-content .prepare-button {
    background-color: #06EFC5;
    padding: 18px 59px;
    font-size: 18.33px;
    font-weight: 800;
    line-height: 13.33px;
    color: #242424;
    cursor: pointer;
    border-radius: 0px;
    font-family: "Inter", sans-serif;
}

.hr-interview .bottom-content .prepare-button:focus {
    box-shadow: none;
}

.hr-interview .bottom-content .bottom-line {
    font-weight: 400;
    line-height: 20px;
    font-size: 16px;
    color: #ffff;
    padding: 18px 54px;
    background-color: #1e102d;
    width: max-content;
    margin: 28px auto 0px;
    font-family: "Inter", sans-serif;
}

@media(max-width: 1020px) {
    .hr-interview {
        padding: 20px 50px;
    }

    .hr-interview .heading {
        line-height: 40px;
    }

    .hr-interview .profile-section {
        margin-bottom: 20px;
    }
}

@media(max-width: 767px) {
    .hr-interview {
        padding: 50px 0px;
        justify-content: unset;
    }

    .hr-interview .profile-sec-wrap {
        min-width: unset;
    }

    .hr-interview .heading {
        line-height: 24px;
        font-size: 16px;
        max-width: 320px;
        margin: 0 auto;
        padding-bottom: 30px;
        font-family: "Inter", sans-serif;
    }

    .hr-interview .profile-img {
        width: 100px;
        height: 100px;
    }

    .hr-interview .profile-section .caller-title {
        font-size: 14px;
        line-height: 22px;
    }

    .hr-interview .profile-section .caller-name {
        font-size: 14px;
        line-height: 22px;
    }

    .hr-interview .bottom-content .description {
        font-size: 12px;
        line-height: 20px;
        opacity: 50%;
    }

    .hr-interview .bottom-content .bottom-line {
        font-size: 10px;
    }

    .hr-interview .profile-sec-wrap .arrow-img-wrap {
        display: none;
    }

    .hr-interview .bottom-content .prepare-button {
        width: 100%;
    }
}