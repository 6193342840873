.caller-card-wrap .left-img-wrap {
    position: relative;
    /* height: 100%; */
}

.caller-card-wrap .left-img-wrap img {
    width: 100%;
}

.caller-card-wrap .left-img-wrap .bottom-icon-wrap {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.81);
    height: 88px;
    width: 100%;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 27px;
}

.caller-card-wrap .left-img-wrap .bottom-icon-wrap .bottom-title {
    font-weight: 700;
    line-height: 75px;
    font-size: 22px;
    color: #000000;
}

.caller-card-wrap .left-img-wrap .bottom-icon-wrap .bottom-title span {
    font-weight: 500;
}

.caller-card-wrap .left-img-wrap .bottom-icon-wrap .feature-icon-wrap {
    display: flex;
}

.caller-card-wrap .left-img-wrap .bottom-icon-wrap .feature-icon-wrap .icon-button {
    background-color: transparent;
    border: none;
}

.caller-card-wrap .left-img-wrap .bottom-icon-wrap .feature-icon-wrap .icn-2 {
    padding-left: 20px;
}

.top-title-mob {
    display: none;
}

@media (max-width: 1650px) {
    .caller-card-wrap .left-img-wrap .bottom-icon-wrap {
        padding: 0px 20px;

    }

    .caller-card-wrap .left-img-wrap .bottom-icon-wrap .bottom-title {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .left-img-wrap .top-title-mob {
        display: block;
        background-color: #ffffff;
        color: #38bbf4;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        border-radius: 10px;
        padding: 6px 10px;
        position: absolute;
        left: 2%;
        top: 5%;
        width: 80%;
        display: flex;
        gap: 7px;
        align-items: center;
    }

    .interview-mode-bottom-content .left-img-wrap .top-img-mic-icon {
        top: 4%;
    }

    .left-img-wrap .top-title-mob span {
        display: none;
    }

    .left-img-wrap .top-img-mic-icon img {
        width: 40px;
        height: 40px;
    }

    .left-img-wrap .warning {
        color: #ffb800;
    }

    .left-img-wrap .error {
        color: #ef3f3f;
    }

    .left-img-wrap .top-title-mob img {
        width: 16px;
        height: 16px;
    }

    .caller-card-wrap .left-img-wrap .caller-img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
    }

    .caller-card-wrap .left-img-wrap .bottom-icon-wrap {
        height: 42px;
        border-radius: 0px 0px 10px 10px;
        padding: 6px 12px;
    }


    .caller-card-wrap .left-img-wrap .bottom-icon-wrap .bottom-title {
        font-size: 12px;
        line-height: 20px;
        font-weight: 700;
    }

    .caller-card-wrap .left-img-wrap .bottom-icon-wrap .bottom-title span {
        font-weight: 500;
    }

    .caller-card-wrap .left-img-wrap .bottom-icon-wrap .feature-icon-wrap img {
        height: 30px;
        width: 30px;
    }

    .caller-card-wrap .left-img-wrap .bottom-icon-wrap .feature-icon-wrap .icn-2 {
        padding-left: 0px;
        margin-left: 12px;
    }
}