.blog-details-page {
    background-color: #150924;
}

.blog-header {
    text-align: center;
    margin-bottom: 20px;
}

.blog-header img {
    width: 100%;
}

.sub-section {
    padding-top: 20px;
    align-items: center;
}

.blog-details-wrap {
    padding: 0px 100px;
}

.main-heading {
    font-size: 42px;
    font-weight: 700;
    line-height: 68px;
    color: #ffffff;
    padding-top: 58px;
}

.main-content {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    padding-bottom: 57px;
    text-align: left;
    color: white;
}

.post-date {
    margin-bottom: 44px;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    color: #ffff;
    opacity: 0.8;
}

.sub-image {
    width: 100%;
    border-radius: 8px;
}

.sub-content .sub-heading {
    font-size: 32px;
    font-weight: 700;
    line-height: 68px;
    margin-bottom: 20px;
    color: #ffff;
}

.sub-content .description {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
}

@media(max-width:768px) {
    .blog-details-wrap {
        padding: 0px 30px;
    }

    .main-heading {
        font-size: 25px;
        line-height: 39px;
        padding-top: 35px;
    }

    .sub-content .sub-heading {
        font-size: 25px;
        line-height: 39px;
    }

    .post-date {
        font-size: 15px;
    }

    .sub-content .description {
        font-size: 15px;
        line-height: 26px;
    }
}