.interview-preparation {
    background-color: rgba(15, 1, 37, 1);
    color: #fff;
    min-height: 100vh;
    padding: 40px 100px 50px 50px;
}

.interview-preparation .interview-mode-header {
    margin-bottom: 30px;
    display: unset;
    border-bottom: none;
    background-color: transparent;
}

.interview-preparation .header-wrap .logo {
    max-width: 100px;
    display: block;
    margin-bottom: 5px;
}

.interview-preparation .interview-details {
    margin-top: 5px;
}

.interview-preparation .header-wrap .title {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    font-family: "Inter", sans-serif;
}

.interview-preparation .interview-details .caller-details {
    font-size: 18px;
    font-weight: 400;
    line-height: 34px;
    font-family: "Inter", sans-serif;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
}

.interview-preparation .caller-details span {
    font-weight: 600;
}

.interview-preparation .caller-details .dot {
    display: block;
}

.interview-preparation .top-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
}

.interview-preparation .status {
    font-size: 24px;
    font-weight: 600;
    line-height: 60px;
    color: #38bbf4;
    font-family: "Inter", sans-serif;
    color: #38bbf4;
    /* margin-bottom: 39px; */
}

.interview-preparation .warning {
    color: #ffb800;
}

.interview-preparation .error {
    color: #ef3f3f;
}

.interview-preparation .errorBg {
    background: #f842421f !important;
}

.interview-preparation .start-interview-button {
    background-color: #06efc5;
    color: #000;
    font-size: 18px;
    font-weight: 800;
    line-height: 13.33px;
    padding: 18px 60px;
    border: none;
    cursor: pointer;
    font-family: "Inter", sans-serif;
    gap: 10px;
}

.interview-preparation .disableButton {
    background-color: #271b3b;
    color: white;
}

.interview-preparation .filterInvert {
    filter: invert(1);
}

.interview-preparation .start-interview-button img {
    width: 20px;
    height: 20px;
}

.interview-preparation .bottom-content {
    display: flex;
    gap: 15px;
}

.interview-preparation .check-box {
    background-color: rgba(6, 239, 197, 0.11);
    padding: 32px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.interview-preparation .check-box .box-content-wrap .box-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 19px;
    font-family: "Inter", sans-serif;
}

.interview-preparation .check-box .box-content-wrap .box-sub-title {
    font-weight: 400;
    font-size: 18px;
    line-height: 37px;
    padding: 16px 0px 10px;
    font-family: "Inter", sans-serif;
}

.interview-preparation .check-box .box-content-wrap .box-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    font-family: "Inter", sans-serif;
}

/* .interview-preparation .check-box .icon-check img {
    width: 14px;
    height: 14px;
} */

.interview-preparation .right-content .top-row {
    display: flex;
    gap: 15px;
}

.interview-preparation .right-content .bottom-row {
    display: flex;
    gap: 15px;
    margin-top: 15px;
}

.bottom-btn-wrap {
    display: none;
}

.interview-preparation .mobile-accrodians {
    display: none;
}

@media (max-width: 1650px) {
    .interview-preparation {
        padding: 30px 100px 30px;
    }

    .interview-preparation .check-box {
        padding: 15px 30px;
    }

    .caller-card-wrap .left-img-wrap .caller-img {
        width: 400px;
    }

    .caller-card-wrap .left-img-wrap .bottom-icon-wrap .bottom-title {
        line-height: 30px;
        font-size: 18px;
    }

    .caller-card-wrap .left-img-wrap .bottom-icon-wrap .feature-icon-wrap .icon-button img {
        width: 40px;
        height: 40px;
    }

    .interview-preparation .check-box .box-content-wrap .box-sub-title {
        padding: 6px 0px;
    }

    .caller-card-wrap .left-img-wrap .bottom-icon-wrap {
        padding: 0px 15px;
    }
}

@media (max-width: 768px) {
    .interview-preparation {
        padding: 50px 20px;
    }

    .interview-preparation .header-wrap .logo {
        margin-bottom: 0px;
    }

    .interview-preparation .header-wrap {
        display: flex;
        justify-content: space-between;
    }

    .interview-preparation .interview-details .caller-details {
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        text-align: center;
        font-family: "Inter", sans-serif;
        flex-direction: column;
        border-bottom: none;
    }

    .interview-preparation .caller-details .dot {
        display: none;
    }

    .interview-preparation .header-wrap .title {
        margin-top: 0px;
        font-size: 14px;
    }

    .caller-card-wrap .left-img-wrap .bottom-icon-wrap .feature-icon-wrap .icon-button img {
        width: 30px;
        height: 30px;
    }

    .interview-mode-wrapper .interview-details {
        padding-top: 15px;
    }

    .interview-mode-wrap {
        padding-top: 10px;
    }

    .interview-preparation .main-content {
        flex-direction: column;
        align-items: center;
    }

    .interview-preparation .left-content,
    .interview-preparation .right-content {
        width: 100%;
    }

    .interview-preparation .start-interview-button {
        position: static;
        margin-top: 15px;
        align-self: center;
    }

    .interview-preparation .bottom-content {
        flex-direction: column;
    }

    .interview-preparation .top-content {
        display: none;
    }

    .bottom-btn-wrap {
        display: block;
    }

    .bottom-btn-wrap .start-interview-button {
        background-color: #06efc5;
        color: #000;
        font-size: 14px;
        font-weight: 800;
        line-height: 13.33px;
        padding: 18px 60px;
        border: none;
        cursor: pointer;
        font-family: "Inter", sans-serif;
        width: 100%;
    }

    .bottom-btn-wrap .start-interview-button img {
        width: 20px;
        height: 20px;
    }

    .interview-preparation .right-box-wrap {
        display: none;
    }

    .interview-preparation .mobile-accrodians {
        display: flex;
    }

    .interview-preparation .interviewAccrodian {
        gap: 18px;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .interview-preparation .interviewAccrodian .accordion-button {
        background: #0e1b37;
    }

    .interview-preparation .interviewAccrodian .accordion-body {
        padding-top: 10px;
        background: #0e1b37;
    }

    .interview-preparation .interviewAccrodian .box-title {
        font-size: 12px;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: -0.02em;
        text-align: left;
        color: white;
    }

    .interview-preparation .interviewAccrodian .box-description {
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.02em;
        text-align: left;
    }

    .interview-preparation .interviewAccrodian .accordian-image {
        width: 16px;
        margin-right: 12px;
    }

    .interview-preparation .interviewAccrodian .accordion-button:not(.collapsed) {
        padding-bottom: 0;
    }

    .interview-preparation .interviewAccrodian .accordion-button::after {
        background-image: url("/public/assets/images/downAccrodianArrow.svg");
    }

    .interview-preparation .disableButton {
        background-color: #271b3b;
        color: white;
    }

    .interview-preparation .filterInvert {
        filter: invert(1);
    }
}