.section3 {
    background: #150924;
    padding: 76px 100px;
    color: white;
}

.section3 .subheading {
    margin-top: 10px;
    font-size: 39px;
    font-weight: 700;
    line-height: 75px;
    text-align: center;
    margin-bottom: 87px;
}

.ResponsiveWrapper {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
}


@media screen and (max-width: 1024px) {
    .ResponsiveWrapper {
        gap: 50px;
    }
}
@media screen and (max-width: 480px) {
    .section3 {
        padding: 40px 25px;
    }
    .ResponsiveWrapper {
        gap: 50px;
    }
}