.footer-section {
    background-color: #150924;
    color: white;
    padding: 4rem 0;
}

.footer-content-wrap {
    padding: 0;
}

.footer-top {
    padding: 50px 150px;
    margin-left: 0;
    margin-right: 0;
}

.footer-left h2 {
    color: white;
    margin: 27px 0px 24px;
    font-size: 24px;
    display: block;
}

.app-buttons {
    display: block;
}

.app-buttons img {
    width: 140px;
    margin-right: 1rem;
}

.footer-right {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 2rem;
    flex-wrap: wrap;
}

.footer-links {
    display: flex;
    flex-direction: column;
}

.footer-links a {
    color: white;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    margin-bottom: 12px;
    text-decoration: none;
}

.footer-links a:hover {
    text-decoration: none !important;
}

.footer-right .title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 20px;
    text-align: left;
    font-family: "Inter", sans-serif;
}

.social-icons {
    display: flex;
    justify-content: flex-end;
    margin-top: 74px;
    margin-bottom: 31px;
}

.social-icons a {
    margin-left: 18px;
}

.footer-bottom {
    padding: 0px 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2rem;
    /* border-top: 1px solid rgba(255, 255, 255, 0.1); */
}

.footer-bottom p {
    margin: 0;
}

.footer-terms {
    display: block;
}

.footer-terms a {
    color: white;
    margin-left: 1.5rem;
    text-decoration: none;
}

.footer-terms a:hover {
    text-decoration: none !important;
}

.border-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-terms-mob {
    display: none;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .footer-top {
        flex-direction: column;
        align-items: center;
        padding: 50px 0px;
    }

    .footer-left {
        text-align: center;
        margin-bottom: 2rem;
    }

    .app-buttons {
        justify-content: center;
    }

    .footer-right {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        margin-bottom: 2rem;

    }

    .footer-right .title {
        text-align: center;
        font-size: 12px;
        font-weight: 700;
    }

    .footer-links {
        text-align: center;
    }

    .social-icons {
        justify-content: center;
        margin: 2rem 0;
    }

    .footer-bottom {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer-bottom p {
        margin-bottom: 1rem;
    }

    .footer-terms {
        margin-top: 1rem;
        display: none;
    }

    .app-buttons {
        display: none;
    }

    .footer-section {
        padding: 0px;
    }

    .footer-terms-mob {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;

    }

    .footer-terms-mob a {
        color: white !important;
        font-size: 12px;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 26px !important;
    }
}

@media (max-width: 480px) {
    .footer-left h2 {
        display: none;
        font-size: 20px;
    }

    .app-buttons img {
        width: 120px;
    }

    .footer-links a {
        font-size: 12px;
    }

    .footer-bottom p {
        font-size: 14px;
    }

    .footer-terms a {
        font-size: 14px;
        margin-left: 1rem;
    }

    .footer-bottom {
        padding: 20px 0px 0px;
    }
}