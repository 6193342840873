.banner-wrap {
    background-color: #150924;
    color: white;
    padding: 91px 100px;
    font-family: "Inter", sans-serif;
}

.banner-content {
    text-align: left;
}

.banner-content .title {
    font-size: 52px;
    font-weight: 700;
    line-height: 68px;
    text-align: left;
    font-family: "Inter", sans-serif;
}

.banner-content .title span {
    background-color: #9256E8;
    color: white;
    padding: 0px 8px 4px;
}

.banner-content .description {
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
    text-align: left;
    max-width: 760px;
    font-family: "Inter", sans-serif;
}

.banner-content .sub-description {
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    padding: 16px 0px;
}

.banner-buttons {
    align-items: center;
}

.banner-buttons button {
    padding: 0.75rem 2rem;
    border: none;
    cursor: pointer;
}

.banner-buttons .btn-dark {
    background-color: #00D49A;
    color: #242424;
    border: none;
    padding: 18px 48px;
    text-align: center;
    font-size: 18.333px;
    font-style: normal;
    font-weight: 800;
    line-height: 13.333px;
}

.banner-buttons .btn-dark img {
    height: 24px;
}

.banner-buttons .btn-light {
    background-color: transparent;
    color: #06EFC5;
    border: 1px solid transparent;
    text-align: center;
    font-size: 18.333px;
    font-style: normal;
    font-weight: 800;
    line-height: 13.333px;
}

.banner-buttons button:active {
    border: 1px solid transparent;
    box-shadow: none;
}

.banner-buttons button:focus {
    border: 1px solid transparent;
    box-shadow: none;
}

.banner-buttons .btn-light img {
    height: 24px;
}

.mob-banner-img {
    display: none;
}

.banner-right-img {
    display: block;
}

.banner-right-img img {
    width: 100%;
    /* height: 100%; */
}

.testimonial-card {
    position: relative;
    max-width: 500px;
    background-color: rgba(255, 255, 255, 0.13);
    padding: 11px 6px 15px 16px;
    border-radius: 30px 30px 30px 0px;
    margin-top: 65px;
}

.testimonial-card .testimonial-quote {
    position: absolute;
    right: 1%;
    top: -8%;
}

.testimonial-card .title {
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 38px;
    letter-spacing: -0.36px;
    margin-bottom: 0px;
    display: flex;
    font-family: "Inter", sans-serif;
}

.testimonial-card .title img {
    padding-right: 6px;
    height: 32px;
}

.testimonial-card .description {
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: -0.24px;
    padding: 9px 0px 5px;
    margin-bottom: 0px;
}

.testimonial-card .client-name {
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: -0.24px;
    position: relative;
    padding-left: 30px;
}

.testimonial-card .client-name::before {
    position: absolute;
    content: '';
    left: 1%;
    bottom: 50%;
    width: 21.5px;
    height: 1px;
    background-color: #FFF;
    opacity: 0.2;
}

@media (max-width: 1024px) {
    .banner-wrap{
        padding: 55px;
    }
}

@media (max-width: 768px) {
    .banner-wrap {
        padding: 40px 25px;
        text-align: center;
    }

    .banner-content .title {
        font-size: 25px;
        line-height: 39px;
        text-align: center;
        margin-bottom: 0px;
    }

    .banner-content .description {
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        padding: 15px 0px;
        margin-bottom: 0px;
    }

    .banner-content .sub-description {
        font-size: 15px;
        line-height: 19px;
        text-align: center;
    }

    .banner-buttons {
        flex-direction: column;
        gap: 0.5rem;

    }

    .banner-buttons .btn-dark {
        width: 100%;
    }

    .banner-buttons .btn-light {
        width: 100%;
    }

    .banner-buttons a {
        width: 100%;
    }

    .mob-banner-img {
        display: block;
    }

    .banner-right-img {
        display: none;
    }

    .testimonial-card {
        margin-bottom: 30px;
    }

    .testimonial-card .title {
        font-size: 18px;
        line-height: 38px;
        letter-spacing: -0.36px;
    }

    .testimonial-card .description {
        font-size: 15px;
        line-height: 23px;
        letter-spacing: -0.3px;
        padding-bottom: 5px;
        text-align: left;
    }

    .testimonial-card .client-name {
        font-size: 12px;
        line-height: 38px;
        letter-spacing: -0.24px;
    }
}