.tranier-simulator {
    min-height: 100vh;
    background-color: #0f0125;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 100px 0px;
}

.tranier-simulator .profile-sec-wrap {
    justify-content: space-between;
    max-width: 750px;
    margin: 0 auto;
    position: relative;
}

.tranier-simulator .profile-sec-wrap .arrow-img-wrap {
    text-align: center;
    position: absolute;
    top: 15%;
    display: block;
    right: -52%;
}

.tranier-simulator .logo-img {
    max-width: 150px;
    margin-bottom: 20px;
}

.tranier-simulator .heading {
    font-size: 26px;
    font-weight: 700;
    line-height: 75px;
    color: white;
    text-align: center;
    margin-bottom: 50px;
    width: unset;
    font-family: "Inter", sans-serif;
}

.tranier-simulator .user-name {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #ffff;
    font-family: "Inter", sans-serif;
}

.tranier-simulator .user-name span {
    font-weight: 700;
}

.tranier-simulator .icon-title {
    font-size: 12px;
    font-weight: 400;
    line-height: 35px;
    font-family: "Inter", sans-serif;
}

.tranier-simulator .profile-section {
    margin-bottom: 40px;
}

.tranier-simulator .profile-section .caller-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    font-family: "Inter", sans-serif;
}

.tranier-simulator .profile-section .caller-name {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    font-family: "Inter", sans-serif;
}

.tranier-simulator .profile-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.tranier-simulator .icons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 10px;
}

.tranier-simulator .icon-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.tranier-simulator .icon-img {
    width: 40px;
    height: 40px;
}

.tranier-simulator .bottom-content {
    justify-content: center;
    overflow: hidden;
}

.tranier-simulator .bottom-content .description {
    max-width: 582px;
    margin: 0 auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding-bottom: 26px;
    font-family: "Inter", sans-serif;
}

.tranier-simulator .bottom-content .prepare-button {
    background-color: #06efc5;
    padding: 18px 59px;
    font-size: 18.33px;
    font-weight: 800;
    line-height: 13.33px;
    color: #242424;
    cursor: pointer;
    border-radius: 0px;
    display: inline-block;
    font-family: "Inter", sans-serif;
    width: 310px;
}

.tranier-simulator .bottom-content .prepare-button-mob {
    background-color: #06efc5;
    padding: 18px 59px;
    font-size: 18.33px;
    font-weight: 800;
    line-height: 13.33px;
    color: #242424;
    cursor: pointer;
    border-radius: 0px;
    display: none;
    font-family: "Inter", sans-serif;
}

.tranier-simulator .bottom-content .prepare-button:focus {
    box-shadow: none;
}

.tranier-simulator .bottom-content .bottom-line {
    font-weight: 400;
    line-height: 20px;
    font-size: 16px;
    color: #ffff;
    padding: 18px 54px;
    background-color: #1e102d;
    width: max-content;
    margin: 28px auto 0px;
    font-family: "Inter", sans-serif;
}

.tranier-simulator .dropdown-wrap .title {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
    font-family: "Inter", sans-serif;
}

.tranier-simulator .dropdown-wrap {
    margin-top: 40px;
}

.tranier-simulator .dropdown-button {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    border: none;
    padding: 14px 20px 14px 12px;
    width: 550px;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 20px;
    font-family: "Inter", sans-serif;
}

.tranier-simulator .dropdown-button.active {
    background-color: rgba(255, 255, 255, 0.1);
}

.tranier-simulator .dropdown-arrow {
    margin-left: 10px;
    transition: transform 0.3s ease;
}

.tranier-simulator .dropdown-arrow img {
    width: 16px;
    height: 16px;
}

.tranier-simulator .dropdown-list {
    list-style-type: none;
    padding: 0;
    margin: 10px auto;
    background-color: #0f0125;
    border: 1px solid #2b1155;
    width: 550px;
    position: absolute;
    z-index: 1000;
    left: 25.25%;
    top: 50px;
}

.tranier-simulator .dropdown-list li {
    padding: 12px;
    cursor: pointer;
    color: white;
    text-align: left;
}

.tranier-simulator .dropdown-selected-text {
    padding: 6px 22px;
    border-radius: 4px;
    color: white;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}

.trainerIntro-drawerDataWrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.trainerIntro-drawerDataWrap .label {
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: -0.02em;
    text-align: left;
    width: 100%;
}

.trainerIntro-drawerDataWrap-heading {
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: -0.02em;
    text-align: center;
}

.tranier-simulator .mobile-trainingSelect-button {
    display: none;
}

@media (max-width: 1650px) {
    .tranier-simulator {
        padding: 40px 0px 20px;
    }

    .tranier-simulator .logo-img {
        margin-bottom: 0px;
    }

    .tranier-simulator .heading {
        line-height: 40px;
        margin-bottom: 20px;
    }

    .tranier-simulator .bottom-content .description {
        padding-bottom: 10px;
    }

    .tranier-simulator .profile-section {
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) {
    .tranier-simulator {
        padding: 50px 0px;
    }

    .tranier-simulator .profile-sec-wrap {
        min-width: unset;
    }

    .tranier-simulator .profile-img {
        width: 100px;
        height: 100px;
    }

    .tranier-simulator .heading {
        line-height: 24px;
        font-size: 16px;
        max-width: 320px;
        margin: 0 auto;
        padding-bottom: 30px;
        font-family: "Inter", sans-serif;
    }

    .tranier-simulator .profile-section .caller-title {
        font-size: 14px;
        line-height: 22px;
    }

    .tranier-simulator .profile-section .caller-name {
        font-size: 14px;
        line-height: 22px;
    }

    .tranier-simulator .bottom-content .description {
        font-size: 12px;
        line-height: 20px;
        opacity: 50%;
        margin-top: 20px;
    }

    .tranier-simulator .bottom-content .bottom-line {
        font-size: 10px;
    }

    .tranier-simulator .dropdown-button {
        width: 100%;
    }

    .tranier-simulator .dropdown-list {
        top: 0;
    }

    .tranier-simulator .bottom-content .prepare-button-mob {
        display: inline-block;
        width: 100%;
    }

    .tranier-simulator .bottom-content .prepare-button {
        display: none;
    }

    .tranier-simulator .profile-sec-wrap .arrow-img-wrap {
        display: none;
    }

    .tranier-simulator .desktop-trainingSelect-button {
        display: none;
    }

    .tranier-simulator .mobile-trainingSelect-button {
        display: block;
    }
}
