.Job-search-section {
    padding-left: 100px;
}

.Job-search-section .wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
}


.Job-search-section .content-wrap .title {
    font-size: 46px;
    font-weight: 700;
    line-height: 58px;
    color: #242424;
    text-align: left;
    font-family: "Inter", sans-serif;
    letter-spacing: 0px;
    margin-top: 50px;
    font-family: "Inter", sans-serif;
}

.Job-search-section .content-wrap .title span {
    color: #ffffff;
    background-color: #9256E8;
    padding: 2px 6px;
}

.Job-search-section .content-wrap .description {
    font-size: 22px;
    line-height: 33px;
    font-weight: 400;
    color: #242424;
    margin: 24px 0px;
}

.Job-search-section .content-wrap .sub-description {
    color: #242424;
    font-weight: 700;
    line-height: 28px;
    font-size: 22px;
    padding-left: 5px;
    border-left: 5px solid #9256E8;
    font-family: "Inter", sans-serif;
}

.Job-search-section .content-wrap .download-btn {
    display: flex;
    margin-top: 24px;
}

.Job-search-section .content-wrap .download-btn .app-store {
    margin-right: 18px;
}

.Job-search-section .content-wrap .download-btn-mob {
    display: none;
}

@media (max-width: 768px) {
    .Job-search-section .wrapper {
        padding-top: 0px;
    }

    .Job-search-section {
        padding: 50px 25px;
    }

    .Job-search-section .content-wrap .title {
        font-size: 25px;
        line-height: 39px;
        margin-top: 0;
    }

    .Job-search-section .content-wrap .description {
        font-size: 15px;
        line-height: 19px;
        margin: 20px 0px;
        text-align: left;
    }

    .Job-search-section .content-wrap .sub-description {
        font-size: 15px;
        line-height: 33px;
    }

    .Job-search-section .right-img {
        margin-top: 40px;
    }

    .Job-search-section .content-wrap .download-btn {
        display: none;
    }

    .Job-search-section .content-wrap .download-btn-mob {
        display: block;
    }
}