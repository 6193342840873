.action-container {
    background-color: #150924;
    color: #fff;
    padding: 50px 0px 100px;
    text-align: center;
}

.action-container .title {
    text-align: center;
    color: #FFF;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    margin-bottom: 30px;
    font-family: "Inter", sans-serif;
}

.action-container .highlight {
    background-color: #A855F7;
    color: #fff;
    padding: 5px;
}

.action-container .description {
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    max-width: 905px;
    margin: 0 auto;
    padding-bottom: 90px;
}

.action-container .sub-title {
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    margin-bottom: 86px;
}

.action-container .steps-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.action-container .steps-wrapper-mob {
    display: none;
}


.action-container .steps-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-left: -8%;
}

.action-container .bottom-row {
    margin-top: 50px;
    margin-left: 8%;
}

.action-container .step-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.action-container .circle {
    width: 60px;
    height: 60px;
    background-color: #14B8A6;
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    position: relative;
    z-index: 1;
    box-shadow: 0 0 0 10px #18122B;
}

.action-container .circle::after {
    content: '';
    position: absolute;
    top: -6px;
    left: -6px;
    right: -6px;
    bottom: -6px;
    border: 2px dashed #14B8A6;
    border-radius: 50%;
    z-index: -1;
}

.action-container .connector {
    width: 300px;
    height: 2px;
    background: repeating-linear-gradient(90deg, #14B8A6, #14B8A6 5px, transparent 5px, transparent 10px);

}

.action-container .before-connector {
    width: 50px;
    height: 2px;
    background: repeating-linear-gradient(90deg, #14B8A6, #14B8A6 5px, transparent 5px, transparent 10px);

}

.action-container .steps-description {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

.action-container .desription-wrap {
    min-width: 350px;
    max-width: 350px;
}

.action-container .step-desc {
    max-width: 300px;
    font-size: 1em;
    line-height: 1.5;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .action-container {
        padding: 20px;
    }

    .action-container .title {
        font-size: 25px;
    }

    .action-container .description {
        font-size: 15px;
        line-height: 19px;
        padding: 28px 20px 20px;
    }

    .action-container .sub-title {
        font-size: 12px;
        line-height: 34px;
        margin-bottom: 20px;
    }

    .action-container .steps-wrapper {
        display: none;

    }

    .action-container .steps-wrapper-mob {
        display: block;
    }

    .step-content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 42px;
        margin-bottom: 38px;
    }

    .steps-wrapper-mob .step-number {
        width: 60px;
        height: 60px;
        background-color: #14B8A6;
        color: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5em;
        position: relative;
        z-index: 1;
        box-shadow: 0 0 0 10px #18122B;
    }

    .steps-wrapper-mob .step-number::after {
        content: '';
        position: absolute;
        top: -6px;
        left: -6px;
        right: -6px;
        bottom: -6px;
        border: 2px dashed #14B8A6;
        border-radius: 50%;
        z-index: -1;
    }

    .steps-wrapper-mob .step-description {
        color: #FFF;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        padding-top: 20px;
    }
}