.chat-container {
    width: 100%;
    max-width: 600px;
    margin: auto;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.chat-container .chat-header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.chat-container .chat-header .title {
    font-weight: 700;
    font-size: 16px;
    color: #000000;
    line-height: 27px;
    font-family: "Inter", sans-serif;
    text-align: left;
    letter-spacing: 0;
}

.chat-container .chat-header .close-icon img {
    width: 24px;
    height: 24px;
}

.chat-messages {
    padding: 5px 17px;
    overflow-y: auto;
    flex-grow: 1;
}

.chat-bubble {
    padding: 10px 0px;
    /* margin-bottom: 15px; */
    border-radius: 10px;
    max-width: 80%;
    word-wrap: break-word;
}

/* .manager-bubble {
   background-color: #38BBF4;
    text-align: left;
} */

.manager-bubble .msg {
    font-weight: 500;
    font-size: 12px;
    color: #ffffff;
    line-height: 19px;
    background-color: #38bbf4;
    text-align: left;
    border-radius: 10px 10px 10px 0px;
    padding: 11px 20px;
    margin-bottom: 0;
}

.user-bubble .msg {
    border-radius: 10px 10px 0px 10px;
    padding: 11px 20px;
    border: 1px solid #eeeeee;
    font-size: 12px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #242424;
}

.user-bubble {
    /* background-color: transparent;
    border: 1px solid #EEEEEE;
    border-radius: 12px; */
    text-align: right;
    margin-left: auto;
}

.chat-info {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.chat-info span {
    color: #000000;
    font-weight: 400;
    font-size: 12px;
    line-height: 27px;
}

.sender-name {
    margin-left: 10px;
    font-size: 14px;
    color: #555;
}

.avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.attachment {
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: space-between;
    background-color: transparent;
    border: 1px solid #eeeeee;
    border-radius: 12px;
    padding: 10px 20px 10px 14px;
}

.attachment-link {
    margin-right: 10px;
    text-decoration: none;
    color: #000000;
    font-size: 12px;
    font-weight: 500;
    line-height: 27px;
}

.attachment-link:hover {
    text-decoration: none !important;
    color: #000000;
}

.file-icon {
    margin-right: 5px;
}

.download-icon {
    cursor: pointer;
    font-size: 12px;
    color: #888;
}

.chat-input {
    display: flex;
    align-items: center;
    border-top: 1px solid #ddd;
    padding: 10px;
    background-color: #f9f9f9;
}

.chat-input input {
    width: 100%;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
}

.chat-input input::placeholder {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    line-height: 27px;
}

.chat-input .input-box-wrap {
    display: flex;
    background-color: #ffffff;
    align-items: center;
    width: 100%;
    padding: 13px 20px 15px 12px;
}

.chat-input .input-box-wrap .attach-file img {
    width: 24px;
    height: 24px;
}

.chat-input .send-btn {
    background-color: #06efc5;
    border: none;
    color: white;
    padding: 13px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .chat-container {
        max-width: 100%;
    }

    .chat-bubble {
        max-width: 100%;
    }

    .chat-input input {
        padding: 8px;
    }

    .chat-input .send-btn {
        padding: 8px;
    }
}
