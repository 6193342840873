.section1 {
    background: #150924;
    color: white;
    padding: 51px 100px;
    flex-wrap: wrap;
    justify-content: center;
}

.section1 .heading-wrap {
    padding-top: 40px;
    flex: 1;
}

.section1 .subheading {
    line-height: 34px;
}

.section1 .mobileImage {
    display: none;
}

.section1 .buttons-group {
    padding-top: 30px;
}

.section1 .buttons-group a {
    text-decoration: none !important;
    text-align: center;
    color: #141414 !important;

}

.section1 .buttons-group a:hover {
    text-decoration: none !important;
}

@media screen and (max-width: 480px) {
    .section1 {
        padding: 30px 25px;
        gap: 50px;
    }

    .section1 .heading-wrap {
        flex: auto;
    }

    .section1 .mobileImage {
        display: block;
        padding: 50px 0;
        position: relative;
    }

    .section1 .desktopImage {
        display: none;
    }

    .section1 .buttons-group {
        padding-top: 0px;
    }

    .heading-wrap span {
        padding: 0px 4px !important;
        display: inline-block;
    }
}